import { Hr, Accordion, ContentGroup } from '@constellation/core';
import styled from 'styled-components';

export const StyledHr = styled(Hr)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StyledContentGroup = styled(ContentGroup)`
  margin-bottom: 0 !important;
`;

export const StyledDiv = styled('div')`
  border-bottom: 1px solid #5f545f !important;
  padding-bottom: ${({ theme }) => `${theme.spacing_01} !important`};
  &:last-child {
    border-bottom: none !important;
  }
    a:link {
    color: ${({ theme }: any) => theme.color_text_brand_1};
    &:hover {
      text-decoration: none !important;
    }
`;

export const StyledAccordion = styled(Accordion)`
  background: none !important;
  border-top-width: ${({ theme }) => `${theme.spacing_none} !important`};
  border-bottom-width: ${({ theme }) => `${theme.spacing_01} !important`};
  &:hover {
    background: none !important;
  }
  > button {
    > div {
      padding: 0 !important;
    }
    > div > div > span {
      font-weight: normal !important;
      font-size: ${({ theme }) =>
        `${theme.typography_text_font_size_s3} !important`};
      color: white !important;
    }
    background: none !important;
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      text-decoration: none !important;
    }
  }
  &:last-child {
    border-bottom-width: ${({ theme }) => `${theme.spacing_none} !important`};
  }
`;
