import React, { ReactElement, useState } from 'react';

import { TextField } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useSelector } from 'react-redux';

import { AgreementFieldProps } from './agreementField.config';
import { RegistrationContent } from '../../routes/registration/Registration.config';
import { RootState } from '../../store';
import { isEmptyString } from '../../utils/isEmptyString';

export default function AgreementField({
  setIsContinueClicked,
  handleAgreementNumber,
}: AgreementFieldProps): ReactElement {
  const { agreementNumber, hasAllRegistrationNumbers } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const {
    emptyFieldErrorMessage,
    agreementFieldLabel,
    registrationFieldLabel,
    registrationErrorMessage,
    agreementErrorMessage,
  } = useContent<RegistrationContent>();
  const [agreementErrMsg, setAgreementErrMsg] = useState('');
  const [agreementNo, setAgreementNo] = useState(agreementNumber);

  const handlePCChange = (e: any): void => {
    setAgreementErrMsg('');
    setAgreementNo(e.target.value.toUpperCase());
    setIsContinueClicked(false);
  };

  const handleErrorMessage = (value): void => {
    if (isEmptyString(value)) {
      setAgreementErrMsg(emptyFieldErrorMessage);
    } else if (hasAllRegistrationNumbers) {
      setAgreementErrMsg(registrationErrorMessage);
    } else {
      setAgreementErrMsg(agreementErrorMessage);
    }
  };

  const handleOnDataValidation = (event: any): void => {
    const { value, validity } = event.target;
    if (isEmptyString(value) || !validity.valid) {
      handleErrorMessage(value);
    } else {
      setAgreementNo(value.toUpperCase());
      handleAgreementNumber(value.toUpperCase());
    }
  };

  const handleLabel = (): string =>
    hasAllRegistrationNumbers ? registrationFieldLabel : agreementFieldLabel;

  const handleRegex = (): string =>
    !hasAllRegistrationNumbers ? '([0-9]){9,10}' : '^[a-zA-Z0-9_]*';
  return (
    <TextField
      label={handleLabel()}
      name="agreementNumberField"
      onChange={(event) => handlePCChange(event)}
      onBlur={handleOnDataValidation}
      error={agreementErrMsg}
      inputWidth="fluid"
      maxLength={10}
      data-testid="agreementNumberField"
      data-cs-mask
      pattern={handleRegex()}
      value={agreementNo}
    />
  );
}
