import React, { ReactElement } from 'react';

import { FooterItem, Footer, Grid, Text, GridItem } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';

import { FooterContent } from './Footer.config';
import {
  StyledFooterDiv,
  StyledLink,
  StyledLinkContanier,
  StyledTextGridItem,
} from './styledFooter';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import * as routes from '../../routes/manifest';

export default function FooterComponent(): ReactElement {
  const {
    footerContent,
    footerTermsText,
    footerPrivacyText,
    footerCookiePolicyText,
  } = useContent<FooterContent>();
  const isDeviceFound = useDeviceResizing();
  const navigate = useNavigate();
  const handleOnTermsAndConditions = (): void => {
    navigate(routes.TermsAndConditions);
  };
  const handleOnPrivacyNotice = (): void => {
    navigate(routes.PrivacyPolicy);
  };
  const handleOnCookiePolicy = (): void => {
    navigate(routes.CookiePolicy);
  };
  const displayLink = (
    txt: string,
    navigateFunction: any,
    dataQaId: string,
  ): ReactElement => (
    <StyledLink
      data-qa-id={dataQaId}
      onClick={navigateFunction}
      data-testid={dataQaId}
    >
      {txt}
    </StyledLink>
  );
  const displayText = (txt: string): ReactElement => (
    <Text size="s1" color="subdued">
      {txt}
    </Text>
  );
  return (
    <Footer marginTop="03" data-qa-id={dataQaIds.footer.container}>
      <FooterItem>
        {!isDeviceFound.tablet ? (
          <Grid alignX="justify">
            <GridItem xl={4} sm={12} lg={4} md={4}>
              <StyledLinkContanier marginBottom="none" marginLeft="none">
                {displayLink(
                  footerTermsText,
                  handleOnTermsAndConditions,
                  dataQaIds.footer.termsAndCondtionLink,
                )}
                {displayLink(
                  footerPrivacyText,
                  handleOnPrivacyNotice,
                  dataQaIds.footer.privacyLink,
                )}
                {displayLink(
                  footerCookiePolicyText,
                  handleOnCookiePolicy,
                  dataQaIds.footer.cookiePolicyLink,
                )}
              </StyledLinkContanier>
            </GridItem>
            <StyledTextGridItem
              xl={8}
              sm={12}
              lg={8}
              md={8}
              isMobileFound={isDeviceFound.mobile}
              isTabletFound={isDeviceFound.tablet}
            >
              {displayText(footerContent)}
            </StyledTextGridItem>
          </Grid>
        ) : (
          <Grid alignX="justify">
            <GridItem md={5}>
              <StyledFooterDiv>
                {displayLink(
                  footerTermsText,
                  handleOnTermsAndConditions,
                  dataQaIds.footer.termsAndCondtionLink,
                )}
                {displayLink(
                  footerPrivacyText,
                  handleOnPrivacyNotice,
                  dataQaIds.footer.privacyLink,
                )}
              </StyledFooterDiv>
              {displayLink(
                footerCookiePolicyText,
                handleOnCookiePolicy,
                dataQaIds.footer.cookiePolicyLink,
              )}
            </GridItem>
            <GridItem md={7}>{displayText(footerContent)}</GridItem>
          </Grid>
        )}
      </FooterItem>
    </Footer>
  );
}
