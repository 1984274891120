import React, { ReactElement, useEffect } from 'react';

import { GridItem, Heading, Grid, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import MarketingDataModal from './marketingDataModal';
import MarketingPreferences from './marketingPreferences';
import { MarketingPreferencesContent } from './marketingPreferences.config';
import { StyledMiddleGridItem } from './styledMarketingPreferences';
import WarningIcon from '../../assets/icons/warningIcon';
import DataModal from '../../components/dataModal/dataModal';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import {
  DataModelAction,
  MarketingDataModelAction,
} from '../../store/action/dataModal.action';
import { marketingPrefsFailAction } from '../../store/action/profile.action';
import { dataLayer } from '../../utils/dataLayer';
import { decodeParams } from '../../utils/decodeParams';
import * as routes from '../manifest';

export default function SetMarketingPreference(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const {
    createAccount,
    marketing,
    selectionNotSavedHeading,
    selectionDescription,
    selectionButton,
    perMarketingSwitches,
  } = useContent<MarketingPreferencesContent>();
  useEffect(() => {
    window.appConfig.JOURNEY_NAME = 'Marketing';
    window.appConfig.JOURNEY_STEP_NAME = 'Marketing preferences';
    window.appConfig.PAGE_ROLE = 'Servicing';
    dataLayer();
  }, []);
  const profileState = useSelector((state: RootState) => state.ProfileReducer);
  const utilsStore = useSelector((state: RootState) => state.UtilsReducer);

  const handleOnPrefBtn = (): void => {
    dispatch(DataModelAction(false));
    navigate(routes.WelcomePage);
  };

  const handleOnCancelChanges = (): void => {
    dispatch(MarketingDataModelAction(true));
  };

  const handleOnMarketingPrefDataModal = (): void => {
    dispatch(DataModelAction(false));
    dispatch(marketingPrefsFailAction(false));
  };

  return utilsStore.showSpinner ? (
    <SpinnerContainer brand={undefined} />
  ) : (
    <>
      <Grid>
        <GridItem lg={2} />
        <StyledMiddleGridItem md={12} sm={12} lg={8}>
          <Text size="s2" marginBottom="03" marginTop="07">
            {createAccount}
          </Text>
          <Heading
            data-testid="marketingHeading"
            size="s7"
            aria-level={1}
            marginBottom="03"
          >
            {marketing}
          </Heading>
          <MarketingPreferences
            switchArray={perMarketingSwitches}
            handleOnSaveBtnClick={handleOnPrefBtn}
            handleCancelChanges={handleOnCancelChanges}
            selected={profileState.marketingPreferences}
            email={decodeParams(
              window.location.search.split('?')[1],
              'email',
              2,
            )}
            accessToken={decodeParams(
              window.location.search.split('?')[1],
              'auth_token',
              0,
            )}
          />
        </StyledMiddleGridItem>
        <GridItem lg={2} />
      </Grid>
      {profileState.isMarketingPrefDataModal && (
        <DataModal
          icon={<WarningIcon colour={theme.color_text_default_1} />}
          heading={selectionNotSavedHeading}
          description={selectionDescription}
          buttonTxt={selectionButton}
          onClick={handleOnMarketingPrefDataModal}
          dataQaId={{
            btn: dataQaIds.setMarketingPreferences.backButton,
          }}
          brand={undefined}
          popupName="Selections not saved"
        />
      )}
      <MarketingDataModal />
    </>
  );
}
