import axios from 'axios';

export const authZMockApi = (subject: string): Promise<object> =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `${window.appConfig.REACT_APP_API_URL}${window.appConfig.REACT_APP_UNAUTH_PROXY}/mta/api/v1/authLite/authorizeToken?sub=${subject}`,
        {
          headers: {
            'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
            'x-lbg-user-id': '12',
          },
        },
      )
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
