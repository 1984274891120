import { Paragraph, ContentGroup } from '@constellation/core';
import styled from 'styled-components';

import { StyledLink } from '../../components/appLayout/Applayout.styled';
import { device } from '../../utils/breakpoints';

export const StyledPrevContentGroup = styled(ContentGroup)`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.color_text_brand_1} !important;
  margin-bottom: ${({ theme }) => theme.spacing_03} !important;
`;

export const StyledHeader = styled(ContentGroup)(
  () => `
    display: flex;
    align-items:center;
    `,
);

export const StyledPreviousLink = styled(StyledLink)`
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  min-width: auto !important;
  color: ${({ theme }) => theme.color_text_brand_1} !important;
  text-decoration: underline !important;
  border-radius: 0 !important;
  &:focus:hover {
    box-shadow: none !important;
  }
`;

export const StyledDiv = styled('div')`
  background-color: ${({ theme }) => theme.color_background_panel_default_1};
  border-radius: ${({ theme }) => theme.spacing_03};
  padding: ${({ theme }) => theme.spacing_03} ${({ theme }) => theme.spacing_05} !important;
`;

export const StyledText = styled(Paragraph)`
  color: ${({ theme }) => theme.color_text_subdued} !important;
`;

export const StyledListDiv = styled('div')`
  width: auto;
  padding: 0 ${({ theme }) => theme.spacing_06} !important;
  a.phoneNumber,
  a.phoneNumber span {
    color: #0f7eb1 !important;
  }
  a.phoneNumber :focus {
    background: none !important;
    box-shadow: none !important;
    color: #0f7eb1 !important;
  }
  ul {
    margin-bottom: ${({ theme }) => theme.spacing_04} !important;
  }
  ul li span,
  ul li span svg {
    color: ${({ theme }) => theme.color_text_default_1} !important;
  }
  ul li span svg.phoneIconMargin,
  ul li span svg.mailIconMargin {
    margin-top: ${({ theme }) => theme.spacing_02} !important;
  }

  @media ${device.md} {
    width: 100%;
  }
`;

export const BorderedDiv = styled('div')`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing_05} 0;
  border-radius: ${({ theme }) => theme.spacing_02};
  border: 1px solid ${({ theme }) => theme.color_border_default_2};
  padding: ${({ theme }) => theme.spacing_05} 0 !important;
  overflow-wrap: break-word;
  @media ${device.md} {
    flex-direction: row;
  }
`;
