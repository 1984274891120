import React, { ReactElement, useEffect } from 'react';

import { Container, GridItem, Icon } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { PrivacyPolicyContent } from './PrivacyPolicy.config';
import {
  StyledGrid,
  StyledPrevContentGroup,
  StyledPreviousLink,
  StyledInnerDiv,
  StyledLink,
  StyledSubHeading,
  StyledText,
  StyledParagraph,
} from './privacyPolicyMta.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { dataLayer } from '../../utils/dataLayer';
import {
  StyledFooterList,
  StyledHelpListItem,
} from '../cookiePolicy/cookiePolicy.styled';
import {
  StyledBoldedParagraph,
  StyledGoBack,
  StyledBottomHr,
} from '../termsAndConditions/termsAndConditions.styled';

export default function PrivacyPolicy(): ReactElement {
  const {
    PrivacyNoticeHeading,
    LastUpdatedHeading,
    PrivacyPromiseHeading,
    PromiseSubHeading,
    PromiseTxt,
    PersonalInformationHeading,
    PersonalInformationContent,
    PrivacyWhoHeading,
    PrivacyWhoTxt,
    PrivacyWhoSubTxt,
    PrivacyWhoList,
    PrivacyWhoListItems,
    PersonalInfoHeading,
    SectionContent,
    SectionTxt,
    SectionSubTxtHeading,
    SectionSubTxt,
    TheControlHeading,
    TheControlTxt,
    TheControlSubTxt,
    WhoWeAreHeading,
    PersonalInfoSubTxt,
    PeronsalInfoService,
    PeronsalInfoFindOutTxt,
    ContactingUsHeading,
    PrivacyNoticeTxt,
    LawHeading,
    LegalTxt,
    PrivacyPromiseTxt,
    PersonalInfoHead,
    PersonalInfoTxt,
    PersonalInfoSub,
    UsePersonalInfo,
    DataProtectionTxt,
    DataProtectionSubTxt,
    BussinessTxt,
    BusinessSub,
    BussinessSubTxt,
    SubstantialSubTxt,
    ReasonToRely,
    ReasonToRelySub,
    ServingAsCustomer,
    UsePersonalInfoTxt,
    UsePersonalInfoSubTxt,
    OurReasonHeading,
    OurReasonSubTxt,
    OurLegitimateInterestsHeading,
    OurLegitimateIntrest,
    BusinessImprovementHeading,
    BusinessImprovementTxt,
    OurReasonContracts,
    OurLegitimateProdServices,
    ManagingOperations,
    UsePeronsalInfoFindOutTxt,
    OurLegitimateEfficientTxt,
    ManagingSecurity,
    DetectTxt,
    LegitimateTxt,
    BussinessMangement,
    BussinessMgmtSubtext,
    LegitimateInterestTxt,
    ProcessingSpecialTxt,
    SubstantialTxt,
    CriminalRecordTxt,
    GroupsOfPersonalInfo,
    GroupsPersonalTxt,
    GroupsSubTxt,
    GroupsSub,
    PersonalInfoType,
    Consent,
    Description,
    DescriptionSubTxt,
    Contact,
    ContactSubTxt,
    SocioDemo,
    SocioDemoTxt,
    Transactional,
    TransactionalTxt,
    Contractual,
    ContractualTxt,
    Locational,
    LocationsalTxt,
    Behavioural,
    BehaviouralTxt,
    Technical,
    TechnicalTxt,
    Comms,
    CommsTxt,
    SocioRel,
    SocioRelTxt,
    OpenData,
    OpenDataTxt,
    UsageData,
    UsageDataTxt,
    DocData,
    DocDataTxt,
    Gender,
    GenderTxt,
    SpecialCat,
    SpecialCatTxt,
    SpecialCatList,
    SpecialCatSub,
    Choices,
    ChoicesTxt,
    NationalId,
    NationalIdTxt,
    CollectInfoHead,
    CollectInfoSub,
    CollectInfoTxt,
    DataHeading,
    DataTxt,
    DataSubTxt,
    DataCollectHead,
    AccountActivity,
    AccountActivitySubText,
    AccountCookiesLink,
    OutsideDataTxt,
    OutsideDataSubTxt,
    OutsideDataSub,
    KeepYourPersonalInfo,
    InformationTxt,
    InformationSubTxt,
    InfoReasonTxt,
    InfoReasonSubTxt,
    CustomerSubTxt,
    NotToGiveInfo,
    ChooseInfoTxt,
    ChooseInfoSubTxt,
    ChooseInfoDelayTxt,
    ChooseInfoContractTxt,
    CookiesTxt,
    HowToUseAppEmailTxt,
    HowToUseAppEmailSubTxt,
    Cookies,
    CookiesParagraph,
    EmailTracking,
    EmailTrackingSubTxt,
    EmailTrackingList,
    EmailTrackingDef,
    SMSTrack,
    CookiePolicy,
    PrivRelayPersonalHeading,
    PrivCreditCardTxt,
    PrivLloydsBankingGroup,
    PrivLBGShare,
    PrivLBGInfo,
    PrivLBGProducts,
    PrivThirdParty,
    PrivThirdPartySub,
    PrivAuth,
    PrivAuthSub,
    PrivAuthList,
    PrivBanking,
    PrivBankingSub,
    PrivBankingList,
    PrivOpenLink,
    PrivBankingListC,
    InsurersHead,
    InsurersSub,
    InsurersList,
    PrivOtherService,
    PrivOtherServiceSub,
    PrivOtherServiceList,
    PrivBusiness,
    PrivBusinessSub,
    PrivBusinessList,
    PrivBusinessThis,
    PrivBusinessSo,
    PrivBusinessLearn,
    PrivBusinessOutside,
    PrivBusinessInclude,
    PrivIncludeList,
    MergerHead,
    MergerSub,
    MergerList,
    SharingInfoHead,
    SharingInfo,
    SharingInfoTxt,
    SharingInfoSubTxt,
    SharingInfoList,
    SharingInfoEnd,
    PrivWorkOutMarktingHeading,
    PrivWorkOut,
    MarketingInfoHead,
    MarketingInfo,
    MarketingDecide,
    MarketingDecideTxt,
    SendMarketing,
    SendMarketingTxt,
    MarketChoice,
    MarketChoiceTxt,
    PrivAutomatedInfo,
    PrivAutomatedInfoSubTxt,
    PrivAutomatedSubTxt,
    PrivUnbiasedTxt,
    PrivUnbiasedSubTxt,
    YourRights,
    PrivReviewTxt,
    PrivPleaseContactUs,
    privCRAHeading,
    privSection,
    privCreditAndIdentity,
    privSearchInfo,
    privExchangeTitle,
    privExchangeInfoList,
    privUsedataHeading,
    privUseDataList,
    PrivMoneyIn,
    privCRABussiness,
    privApplyForSomeOne,
    privRecordsLinked,
    privCRArecords,
    privMoreOnCRA,
    privWhoAreTheyList,
    privLinksToInfo,
    privTransUnionLink,
    privEquifax,
    privExperian,
    PrivCRAEnd,
    privFPAHeading,
    PrivFPASub,
    privConfirmIdentity,
    privBecomeCustomer,
    agencyAndBody,
    preventDetect,
    obeyingTheLaw,
    legitimateIntrestTitle,
    useInformationTitle,
    useInfoContent,
    otherOrgTitle,
    personalInfoHeading,
    PersonalInfoTitle,
    KindsOfPersonalInfo,
    AutomatedDecHead,
    ThirdPartyContent,
    PreventFraudContent,
    AffectTitle,
    DataFPA,
    DataFPAResult,
    DataTransferUKaandEEA,
    FPADescription,
    FPATitle,
    DataOutsideUkandEEA,
    DataOutsideUkandEEAContent,
    DataOutside,
    DataOfEEA,
    DoTransfer,
    Transfer,
    TransferContent,
    DataPrivacy,
    DataPrivacySub,
    DataPrivacyContent,
    ContactUs,
    ContactUsSub,
    QuestionTxt,
    RelayTxt,
    BSLTxt,
    DSARTxt,
    DSARSub,
    DSARAddress,
    GDPOTxt,
    GDPOAddress,
    UnhappyHeading,
    UnhappyTxt,
    ComplaintHeading,
    ComplaintTxt,
    UKTxt,
    JerseyTxt,
    GuernseyTxt,
    IOMTxt,
    goBackLink,
  } = useContent<PrivacyPolicyContent>();
  const handleOnNavigateBack = () => {
    window.history.back();
  };
  useEffect(() => {
    window.appConfig.JOURNEY_NAME = 'Privacy Notice';
    window.appConfig.JOURNEY_STEP_NAME = 'Privacy Notice';
    window.appConfig.PAGE_ROLE = 'Information';
    dataLayer();
  }, []);
  const isDeviceFound = useDeviceResizing();
  const handleOnLink = (txt: string, link: string): ReactElement => (
    <>
      <StyledLink href={link} target="_blank">
        {txt}
      </StyledLink>
      <br />
    </>
  );

  const handleOnSubText = (text: string): ReactElement => {
    const textContent = text.split('//');
    const id = 0;
    return (
      <>
        {textContent.map((value: string, index) => (
          <React.Fragment key={`SubTxt-${id + index}`}>
            <StyledText>{value}</StyledText>
            <br />
          </React.Fragment>
        ))}
      </>
    );
  };

  const handleOnListItems = (text: string): ReactElement => {
    const textContent = text.split('//');
    const id = 0;
    return (
      <>
        {textContent.map((value: string, index) => (
          <StyledFooterList key={`ListItems-${id + index}`} marginBottom="none">
            <StyledHelpListItem>{value}</StyledHelpListItem>
          </StyledFooterList>
        ))}
      </>
    );
  };

  const renderContent = (listContent: string): ReactElement => {
    const textContent = listContent.split('//');
    const id = 0;
    return (
      <>
        {textContent.map((value: string, index) => (
          <StyledParagraph key={`Paragraph-${id + index}`} marginBottom="05">
            {value}
          </StyledParagraph>
        ))}
      </>
    );
  };

  const handleOnheading = (
    isBold: boolean,
    isSubheading: boolean,
    heading: string,
    size: any,
    marginBottom?: any,
    marginTop?: any,
  ): ReactElement => (
    <StyledSubHeading
      isSubheading={isSubheading}
      isBold={isBold}
      size={size}
      marginBottom={marginBottom}
      marginTop={marginTop}
      aria-level={4}
    >
      {heading}
    </StyledSubHeading>
  );
  const handleOnParagraph = (
    isBold: boolean,
    txt: string,
    marginBottom?: any,
    marginTop?: any,
    isSubheading = false,
  ): ReactElement => (
    <StyledBoldedParagraph
      isSubheading={isSubheading}
      size="s2"
      marginTop={marginTop}
      marginBottom={marginBottom}
      isBold={isBold}
      dangerouslySetInnerHTML={{ __html: txt }}
    >
      {}
    </StyledBoldedParagraph>
  );
  return (
    <Container marginBottom="05" padding="none">
      <StyledPrevContentGroup
        marginBottom="none"
        className="navigatePreviousLink"
      >
        <StyledPreviousLink
          className="linkBtn"
          onClick={handleOnNavigateBack}
          data-testid="navigatePreviousLink"
          data-qa-id={dataQaIds.helpCentre.previousLink}
        >
          <Icon name="chevron-left" color="brand" />
          Previous
        </StyledPreviousLink>
      </StyledPrevContentGroup>
      <StyledGrid>
        <GridItem lg={2} />
        <GridItem lg={8}>
          <StyledInnerDiv
            className={isDeviceFound.mobile ? 'mobileFound' : 'notMobile'}
          >
            {handleOnheading(false, false, PrivacyNoticeHeading, 's5', '07')}
            {handleOnheading(true, false, LastUpdatedHeading, 's2', '05')}
            {handleOnheading(true, false, PrivacyPromiseHeading, 's4', '05')}
            {handleOnParagraph(false, PromiseSubHeading, '05', 'none')}
            {handleOnListItems(PromiseTxt)}
            {handleOnheading(
              true,
              false,
              PersonalInformationHeading,
              's3',
              '05',
              '05',
            )}
            {handleOnParagraph(false, PersonalInformationContent)}
            {handleOnheading(true, false, PrivacyWhoHeading, 's3', '05', '05')}
            {handleOnParagraph(false, PrivacyWhoTxt)}
            {handleOnParagraph(false, PrivacyWhoSubTxt)}
            {handleOnParagraph(false, PrivacyWhoList)}
            {handleOnListItems(PrivacyWhoListItems)}
            {handleOnheading(
              true,
              false,
              PersonalInfoHeading,
              's2',
              '05',
              '07',
            )}
            {handleOnParagraph(false, SectionContent)}
            {handleOnSubText(SectionTxt)}
            {handleOnheading(
              false,
              false,
              SectionSubTxtHeading,
              's2',
              'none',
              '05',
            )}
            {handleOnSubText(SectionSubTxt)}
            {handleOnheading(
              false,
              true,
              TheControlHeading,
              's2',
              'none',
              '05',
            )}
            {handleOnParagraph(false, TheControlTxt)}
            {handleOnSubText(TheControlSubTxt)}
            {handleOnheading(true, true, PersonalInfoHeading, 's3', '05', '05')}
            {handleOnheading(true, true, WhoWeAreHeading, 's3')}
            {handleOnParagraph(false, PersonalInfoSubTxt)}
            {handleOnParagraph(false, PeronsalInfoService)}
            {handleOnParagraph(false, PeronsalInfoFindOutTxt)}
            {handleOnheading(
              true,
              true,
              ContactingUsHeading,
              's2',
              '05',
              'none',
            )}
            {handleOnParagraph(false, PrivacyNoticeTxt)}
            {handleOnheading(true, true, LawHeading, 's3', '05', 'none')}
            {handleOnParagraph(false, LegalTxt)}
            {handleOnParagraph(false, PrivacyPromiseTxt)}
            {handleOnheading(true, true, PersonalInfoHead, 's2')}
            {handleOnParagraph(false, PersonalInfoTxt)}
            {handleOnParagraph(false, PersonalInfoSub)}
            {handleOnheading(true, true, UsePersonalInfo, 's2')}
            {handleOnParagraph(false, DataProtectionTxt, '05')}
            {handleOnListItems(DataProtectionSubTxt)}
            {handleOnParagraph(false, BussinessTxt, '05', '07')}
            {handleOnParagraph(false, BusinessSub)}
            {handleOnParagraph(false, BussinessSubTxt, '05')}
            {handleOnListItems(SubstantialSubTxt)}
            {handleOnParagraph(false, ReasonToRely, '05', '07')}
            {handleOnParagraph(false, ReasonToRelySub)}
            {handleOnParagraph(true, ServingAsCustomer, '05')}
            {handleOnParagraph(false, OurReasonHeading, '05', '07')}
            {handleOnListItems(OurReasonSubTxt)}

            {handleOnParagraph(false, UsePersonalInfoTxt, '05', '05')}
            {handleOnListItems(UsePersonalInfoSubTxt)}
            {handleOnParagraph(
              false,
              OurLegitimateInterestsHeading,
              '05',
              '07',
            )}
            {handleOnListItems(OurLegitimateIntrest)}
            {handleOnParagraph(
              true,
              BusinessImprovementHeading,
              '05',
              '07',
              false,
            )}
            {handleOnParagraph(false, OurReasonHeading, '05', '05')}
            {handleOnListItems(OurReasonContracts)}
            {handleOnParagraph(false, UsePersonalInfoTxt, '05', '05')}
            {handleOnListItems(BusinessImprovementTxt)}
            {handleOnParagraph(
              false,
              OurLegitimateInterestsHeading,
              '05',
              '07',
            )}
            {handleOnListItems(OurLegitimateProdServices)}
            {handleOnParagraph(true, ManagingOperations, '05', '05', false)}
            {handleOnParagraph(false, OurReasonHeading, '05', '05')}
            {handleOnListItems(OurReasonContracts)}
            {handleOnParagraph(false, UsePersonalInfoTxt, '05', '05')}
            {handleOnListItems(UsePeronsalInfoFindOutTxt)}
            {handleOnParagraph(
              false,
              OurLegitimateInterestsHeading,
              '05',
              '07',
            )}
            {handleOnListItems(OurLegitimateEfficientTxt)}
            {handleOnParagraph(true, ManagingSecurity, '05', '07', false)}
            {handleOnParagraph(false, OurReasonHeading, '05', '05')}
            {handleOnListItems(OurReasonContracts)}
            {handleOnParagraph(false, UsePersonalInfoTxt, '05', '05')}
            {handleOnListItems(DetectTxt)}
            {handleOnParagraph(
              false,
              OurLegitimateInterestsHeading,
              '05',
              '07',
            )}
            {handleOnListItems(LegitimateTxt)}
            {handleOnParagraph(true, BussinessMangement, '05', '07', false)}
            {handleOnParagraph(false, OurReasonHeading, '05', '05')}
            {handleOnListItems(OurReasonContracts)}
            {handleOnParagraph(false, UsePersonalInfoTxt, '05', '05')}
            {handleOnListItems(BussinessMgmtSubtext)}
            {handleOnParagraph(
              false,
              OurLegitimateInterestsHeading,
              '05',
              '07',
            )}
            {handleOnListItems(LegitimateInterestTxt)}
            {handleOnParagraph(true, ProcessingSpecialTxt, '05', '07', false)}
            {handleOnParagraph(false, OurReasonHeading, '05', '05')}
            {handleOnListItems(CriminalRecordTxt)}
            {handleOnParagraph(false, UsePersonalInfoTxt, '05', '05')}
            {handleOnListItems(SubstantialTxt)}
            {handleOnheading(
              true,
              false,
              GroupsOfPersonalInfo,
              's3',
              '05',
              '05',
            )}
            {handleOnParagraph(false, GroupsPersonalTxt, 'none', 'none')}
            {handleOnParagraph(false, GroupsSubTxt, '05', '05')}
            {handleOnParagraph(false, GroupsSub, '05', '05')}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Consent, '05', 'none')}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, DescriptionSubTxt, '05', 'none')}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Contact)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, ContactSubTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, SocioDemo)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, SocioDemoTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Transactional)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, TransactionalTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Contractual)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, ContractualTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Locational)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, LocationsalTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Behavioural)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, BehaviouralTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Technical)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, TechnicalTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Comms)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, CommsTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, SocioRel)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, SocioRelTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, OpenData)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, OpenDataTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, UsageData)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, UsageDataTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, DocData)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, DocDataTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Gender)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, GenderTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, SpecialCat)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, SpecialCatTxt)}
            {handleOnListItems(SpecialCatList)}
            {handleOnParagraph(false, SpecialCatSub, '05', '05')}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, Choices)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, ChoicesTxt)}
            {handleOnParagraph(true, PersonalInfoType, 'none', 'none')}
            {handleOnParagraph(false, NationalId)}
            {handleOnParagraph(true, Description, 'none', 'none')}
            {handleOnParagraph(false, NationalIdTxt)}
            {handleOnheading(true, false, CollectInfoHead, 's3', '05', '05')}
            {handleOnParagraph(false, CollectInfoSub)}
            {handleOnParagraph(false, CollectInfoTxt)}
            {handleOnheading(true, true, DataHeading, 's2', '05', '05')}
            {handleOnParagraph(false, DataTxt, '05', 'none')}
            {handleOnListItems(DataSubTxt)}
            {handleOnheading(true, true, DataCollectHead, 's2')}
            {handleOnParagraph(false, AccountActivity, '05', '07')}
            {handleOnListItems(AccountActivitySubText)}
            {handleOnParagraph(false, AccountCookiesLink, '05', '05')}
            {handleOnheading(true, true, OutsideDataTxt, 's2', '05', '05')}
            {handleOnListItems(OutsideDataSubTxt)}
            {handleOnParagraph(false, OutsideDataSub, '05', '05')}
            {handleOnheading(
              true,
              false,
              KeepYourPersonalInfo,
              's3',
              '05',
              '07',
            )}
            {handleOnParagraph(false, InformationTxt, '05', '05')}
            {handleOnParagraph(false, InformationSubTxt, '05', '05')}
            {handleOnListItems(InfoReasonSubTxt)}
            {handleOnParagraph(false, InfoReasonTxt, '05', '05')}
            {handleOnParagraph(false, CustomerSubTxt, '05', '05')}
            {handleOnheading(true, true, NotToGiveInfo, 's3', '05', '05')}
            {handleOnParagraph(false, ChooseInfoTxt)}
            {handleOnParagraph(false, ChooseInfoSubTxt)}
            {handleOnParagraph(false, ChooseInfoDelayTxt)}
            {handleOnParagraph(false, ChooseInfoContractTxt, '05', '05')}
            {handleOnheading(true, true, CookiesTxt, 's3', '05', '05')}
            {handleOnParagraph(false, HowToUseAppEmailTxt)}
            {handleOnParagraph(false, HowToUseAppEmailSubTxt)}
            {handleOnParagraph(true, Cookies)}
            {handleOnParagraph(false, CookiesParagraph)}
            {handleOnParagraph(true, EmailTracking, '05', '05')}
            {handleOnParagraph(false, EmailTrackingSubTxt)}
            {handleOnListItems(EmailTrackingList)}
            {handleOnParagraph(false, EmailTrackingDef)}
            {handleOnParagraph(false, SMSTrack)}
            {handleOnParagraph(false, CookiePolicy, '05', '05')}
            {handleOnheading(
              true,
              true,
              SectionSubTxtHeading,
              's4',
              '05',
              '05',
            )}
            {handleOnheading(
              true,
              true,
              PrivRelayPersonalHeading,
              's3',
              '05',
              '05',
            )}
            {handleOnParagraph(false, PrivCreditCardTxt, 'none', 'none')}
            {handleOnParagraph(true, PrivLloydsBankingGroup, '05', '05')}
            {handleOnParagraph(false, PrivLBGShare, '05', '05')}
            {handleOnParagraph(false, PrivLBGInfo, '05', '05')}
            {handleOnParagraph(false, PrivLBGProducts, '05', '05')}
            {handleOnParagraph(true, PrivThirdParty)}
            {handleOnParagraph(false, PrivThirdPartySub)}
            {handleOnParagraph(true, PrivAuth)}
            {handleOnParagraph(false, PrivAuthSub)}
            {handleOnListItems(PrivAuthList)}
            {handleOnParagraph(true, PrivBanking, '05', '05')}
            {handleOnParagraph(false, PrivBankingSub)}
            {handleOnListItems(PrivBankingList)}
            {handleOnParagraph(false, PrivOpenLink)}
            {handleOnListItems(PrivBankingListC)}
            {handleOnParagraph(true, InsurersHead, '05', '05')}
            {handleOnParagraph(false, InsurersSub)}
            {handleOnListItems(InsurersList)}
            {handleOnParagraph(true, PrivOtherService, '05', '05')}
            {handleOnParagraph(false, PrivOtherServiceSub)}
            {handleOnListItems(PrivOtherServiceList)}
            {handleOnParagraph(true, PrivBusiness, '05', '05')}
            {handleOnParagraph(false, PrivBusinessSub)}
            {handleOnListItems(PrivBusinessList)}
            {handleOnParagraph(false, PrivBusinessThis, '05', '05')}
            {handleOnListItems(PrivBusinessSo)}
            {handleOnParagraph(false, PrivBusinessLearn, '05', '05')}
            {handleOnParagraph(false, PrivBusinessOutside)}
            {handleOnParagraph(false, PrivBusinessInclude)}
            {handleOnListItems(PrivIncludeList)}
            {handleOnParagraph(true, MergerHead, '05', '05')}
            {handleOnParagraph(false, MergerSub)}
            {handleOnListItems(MergerList)}
            {handleOnParagraph(true, SharingInfoHead, '05', '05')}
            {handleOnParagraph(false, SharingInfo)}
            {handleOnParagraph(false, SharingInfoTxt)}
            {handleOnParagraph(false, SharingInfoSubTxt)}
            {handleOnListItems(SharingInfoList)}
            {handleOnParagraph(false, SharingInfoEnd, '05', '05')}
            {handleOnheading(
              true,
              true,
              PrivWorkOutMarktingHeading,
              's3',
              '05',
              '05',
            )}
            {renderContent(PrivWorkOut)}
            {handleOnParagraph(true, MarketingInfoHead)}
            {handleOnParagraph(false, MarketingInfo)}
            {handleOnParagraph(true, MarketingDecide)}
            {renderContent(MarketingDecideTxt)}
            {handleOnParagraph(true, SendMarketing)}
            {handleOnParagraph(false, SendMarketingTxt)}
            {handleOnParagraph(true, MarketChoice)}
            {renderContent(MarketChoiceTxt)}
            {handleOnheading(true, true, PrivAutomatedInfo, 's2', '05', '05')}
            {renderContent(PrivAutomatedInfoSubTxt)}
            {handleOnSubText(PrivAutomatedSubTxt)}
            {handleOnParagraph(false, PrivUnbiasedTxt, '05', '05')}
            {handleOnParagraph(false, PrivUnbiasedSubTxt, '05', '05')}
            {handleOnParagraph(false, YourRights)}
            {handleOnParagraph(false, PrivReviewTxt, 'none', 'none')}
            {handleOnParagraph(false, PrivPleaseContactUs, 'none', '05')}
            {handleOnheading(true, true, privCRAHeading, 's3', '05')}
            {handleOnParagraph(false, privSection)}
            {handleOnParagraph(false, privCreditAndIdentity, '05')}
            {handleOnParagraph(false, privSearchInfo, '05')}
            {handleOnParagraph(false, privExchangeTitle)}
            {handleOnListItems(privExchangeInfoList)}
            {handleOnParagraph(false, privUsedataHeading, '05', '05')}
            {handleOnListItems(privUseDataList)}
            {handleOnParagraph(false, PrivMoneyIn, '05', '05')}
            {handleOnParagraph(false, privCRABussiness, '05', '05')}
            {handleOnParagraph(false, privApplyForSomeOne, '05', 'none')}
            {handleOnParagraph(false, privRecordsLinked, '05', 'none')}
            {handleOnParagraph(false, privCRArecords, '05', 'none')}
            {handleOnParagraph(false, privMoreOnCRA, '05', 'none')}

            {handleOnListItems(privWhoAreTheyList)}
            {handleOnParagraph(false, privLinksToInfo, '05', '05')}
            {handleOnLink(
              privTransUnionLink,
              'https://www.transunion.co.uk/legal/privacy-centre?#pc-credit-reference#pc-credit-reference',
            )}
            {handleOnLink(privEquifax, 'https://www.equifax.co.uk/crain')}
            {handleOnLink(
              privExperian,
              'https://www.experian.co.uk/legal/crain',
            )}
            {handleOnParagraph(false, PrivCRAEnd, '05', '05')}
            {handleOnheading(true, false, privFPAHeading, 's3', '05', '05')}
            {handleOnParagraph(false, PrivFPASub)}
            {handleOnParagraph(false, privConfirmIdentity, '05')}
            {handleOnParagraph(false, privBecomeCustomer, '05')}
            {handleOnListItems(agencyAndBody)}
            {handleOnParagraph(false, preventDetect, '05', '05')}
            {handleOnParagraph(false, obeyingTheLaw, '05', '05')}
            {handleOnParagraph(false, legitimateIntrestTitle)}
            {handleOnParagraph(false, useInformationTitle, '05', '05')}
            {handleOnListItems(useInfoContent)}
            {handleOnParagraph(false, otherOrgTitle, '05', '05')}
            {handleOnParagraph(true, personalInfoHeading, '05', 'none', true)}
            {handleOnParagraph(false, PersonalInfoTitle, '05')}
            {handleOnListItems(KindsOfPersonalInfo)}
            {handleOnParagraph(true, AutomatedDecHead, '05', '05')}
            {handleOnParagraph(false, ThirdPartyContent, '05', '05')}
            {handleOnParagraph(false, PreventFraudContent, '05', 'none')}
            {handleOnParagraph(true, AffectTitle, '05', '05', true)}
            {handleOnParagraph(false, DataFPA, '05', '05')}
            {handleOnParagraph(false, DataFPAResult, '05', '05')}
            {handleOnParagraph(true, DataTransferUKaandEEA, '05', '05')}
            {handleOnParagraph(false, FPADescription, 'none')}
            {handleOnParagraph(false, FPATitle, '05', '05')}
            {handleOnheading(
              true,
              false,
              DataOutsideUkandEEA,
              's3',
              '05',
              '05',
            )}
            {handleOnParagraph(false, DataOutsideUkandEEAContent, 'none')}
            {handleOnParagraph(false, DataOutside, '05', '05')}
            {handleOnListItems(DataOfEEA)}
            {handleOnParagraph(false, DoTransfer, '05', '05')}
            {handleOnParagraph(false, Transfer, '05', '05')}
            {handleOnParagraph(false, TransferContent, 'none', 'none')}
            {handleOnheading(true, false, TheControlHeading, 's4', '05', '05')}
            {handleOnheading(true, true, DataPrivacy, 's3', '05', '05')}
            {handleOnParagraph(false, DataPrivacySub)}
            {renderContent(DataPrivacyContent)}
            {handleOnheading(true, false, ContactUs, 's3')}
            {handleOnParagraph(false, ContactUsSub)}
            {handleOnParagraph(false, QuestionTxt)}
            {handleOnParagraph(false, RelayTxt)}
            {handleOnParagraph(false, BSLTxt)}
            {handleOnParagraph(false, DSARTxt)}
            {handleOnParagraph(false, DSARSub)}
            {handleOnSubText(DSARAddress)}
            {renderContent(GDPOTxt)}
            {handleOnSubText(GDPOAddress)}
            {handleOnheading(true, true, UnhappyHeading, 's2')}
            {handleOnParagraph(false, UnhappyTxt)}
            {handleOnheading(true, true, ComplaintHeading, 's2', '05', 'none')}
            {handleOnParagraph(false, ComplaintTxt)}
            {handleOnParagraph(false, UKTxt)}
            {handleOnParagraph(false, JerseyTxt)}
            {handleOnParagraph(false, GuernseyTxt)}
            {handleOnParagraph(false, IOMTxt)}
          </StyledInnerDiv>
          <StyledBottomHr />
          <StyledGoBack>
            <LinkButton
              handleButtonClick={handleOnNavigateBack}
              testId="goBackLink"
            >
              {goBackLink}
            </LinkButton>
          </StyledGoBack>
        </GridItem>
        <GridItem />
      </StyledGrid>
    </Container>
  );
}
