import axios from 'axios';

import { ApiEndpoints } from '../constants/apiEndpoints';
import { isBld, isLocal, isNftInt } from '../utils/handleEnvVariables';

const encodeBody = (authToken, codeVerifier): any => {
  const body = {
    grant_type: 'authorization_code',
    redirect_uri: window.appConfig.REACT_APP_AUTH_URL,
    code: authToken,
    client_id: 'AL21582MyTransportAssistantPublicClient',
    code_verifier: codeVerifier,
    scope: 'cloud-access',
  };
  return Object.keys(body)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
    .join('&');
};
const config = {
  headers: {
    'x-lbg-client-id':
      isBld || isLocal || isNftInt
        ? 'xxxx'
        : window.appConfig.REACT_APP_CLIENT_ID,
    'x-lbg-tracking-id': 'f81d4fae-7dec-11d0-a765-00a0c91e6bf6',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

export const authZTokenAPI = (authToken, codeVerifier): Promise<object> =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${window.appConfig.REACT_APP_AUTH_Z}/${ApiEndpoints.AUTH_Z_TOKEN}`,
        encodeBody(authToken, codeVerifier),
        {
          headers: {
            ...config.headers,
            'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
          },
        },
      )
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
