import React, { ReactElement, useCallback, useEffect } from 'react';

import { Grid, GridItem, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useDispatch, useSelector } from 'react-redux';

import AgreementSelectorCTA from './agreementSelectorCTA';
import { MultiAgreementContent } from './multiAgreement.config';
import { authMultiContractsApi } from '../../apis/authMultiContractsApi';
import NeedHelp from '../../components/needHelp/NeedHelp';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { MultiAgreementAction } from '../../store/action/multiAgreement.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dataLayer } from '../../utils/dataLayer';
import { decodeParams } from '../../utils/decodeParams';

export default function MultiAgreements(): ReactElement {
  const { heading } = useContent<MultiAgreementContent>();
  const { customerData } = useSelector(
    (state: RootState) => state.MultiAgreementReducer,
  );
  const { showSpinner } = useSelector((state: RootState) => state.UtilsReducer);
  const dispatch = useDispatch();
  const emailAddress = decodeParams(
    window.location.search.split('?')[1],
    'email',
    2,
  );
  const accessToken = decodeParams(
    window.location.search.split('?')[1],
    'auth_token',
    0,
  );

  const data = useCallback(() => {
    window.sessionStorage.setItem('multiContract', '1');
    window.sessionStorage.removeItem('contractNumber');
    dispatch(ShowHideSpinnerAction(true));
    authMultiContractsApi(emailAddress, {
      Authorization: `Bearer ${accessToken}`,
    })
      .then((res: any) => {
        dispatch(MultiAgreementAction(res));
        dispatch(ShowHideSpinnerAction(false));
      })
      .catch(() => {
        dispatch(DataModelAction(true));
        dispatch(ShowHideSpinnerAction(false));
      });
  }, [accessToken, dispatch, emailAddress]);
  useEffect(() => {
    data();
  }, [data]);
  useEffect(() => {
    window.appConfig.JOURNEY_NAME = 'Multiple Agreement';
    window.appConfig.JOURNEY_STEP_NAME = 'Multiple Agreement';
    window.appConfig.PAGE_ROLE = 'Information';
    dataLayer();
  }, []);

  const handleText = (replaceTxt: string): string =>
    replaceTxt.replace('{{number}}', `${customerData.length}`);

  const renderMultiAgreement = (): ReactElement => {
    return (
      <Grid alignX="center">
        <GridItem lg={8} md={8}>
          <Heading size="s5" marginBottom="07" data-testid="heading">
            {handleText(heading)}
          </Heading>
        </GridItem>
        <GridItem lg={8} md={8}>
          <AgreementSelectorCTA
            accessToken={accessToken}
            emailAddress={emailAddress}
          />
        </GridItem>
        <GridItem md={8}>
          <NeedHelp hasHeading />
        </GridItem>
      </Grid>
    );
  };
  const renderSpinner = (): ReactElement => (
    <SpinnerContainer brand={undefined} />
  );
  return showSpinner ? renderSpinner() : renderMultiAgreement();
}
