export const isLocal = window.location.hostname.indexOf('localhost') !== -1;
export const isBld = window.location.hostname.indexOf('bld') !== -1;
export const isInt =
  window.location.hostname.indexOf('int.customerportal') !== -1 &&
  window.location.hostname.indexOf('dev') === -1;
export const isPre =
  (window.location.hostname.indexOf('pre') !== -1 &&
    window.location.hostname.indexOf('dev') === -1) ||
  window.location.hostname.indexOf('test.customerportal') !== -1;
export const isNftInt =
  window.location.hostname.indexOf('int2.customerportal') !== -1 &&
  window.location.hostname.indexOf('dev') === -1;
const isNftPre =
  window.location.hostname.indexOf('pre') !== -1 &&
  window.location.hostname.indexOf('dev') !== -1;

export const isLive =
  window.location.hostname.indexOf('customerportal.blackhorse') === 0 &&
  (!isPre || !isInt);

const liveURL = 'https://customerportal.blackhorse.co.uk';

const handleTagEnv = (): void => {
  if (isInt || isPre || isNftInt || isNftPre) {
    window.appConfig.REACT_APP_TAG_ENV = 'qa';
  } else if (isBld || isLocal) {
    window.appConfig.REACT_APP_TAG_ENV = 'dev';
  } else {
    window.appConfig.REACT_APP_TAG_ENV = 'prod';
  }
};

const handleApiUrl = (): void => {
  if (isInt) {
    window.appConfig.REACT_APP_API_URL =
      'https://atlas-int-01-channel-api.blackhorse.co.uk';
  } else if (isBld || isLocal) {
    window.appConfig.REACT_APP_API_URL =
      'https://tnt01-alpha-mta-customer-details.ew2.tnt01-bld.oncp.dev';
  } else if (isPre) {
    window.appConfig.REACT_APP_API_URL =
      'https://atlas-pre-01-channel-api.blackhorse.co.uk';
  } else if (isNftInt) {
    window.appConfig.REACT_APP_API_URL =
      'https://tnt01-beta-mta-customer-details.ew2.tnt01-int.oncp.group';
  } else if (isNftPre) {
    window.appConfig.REACT_APP_API_URL =
      'https://dev-mta-customer-details.ew2.tnt01-pre.oncp.group';
  } else {
    window.appConfig.REACT_APP_API_URL =
      'https://atlas-channel-api.blackhorse.co.uk';
  }
};
const handleClientId = (): void => {
  if (isInt) {
    window.appConfig.REACT_APP_CLIENT_ID =
      'cOcpuVtU3oZ4h1GTFNlKUJJohyswnKkYkkXbw95hohyjyTAz';
  } else if (isBld || isLocal || isNftInt) {
    window.appConfig.REACT_APP_CLIENT_ID = '';
  } else if (isPre) {
    window.appConfig.REACT_APP_CLIENT_ID =
      '8p28S8f9C7ShQIKV5gMGW2T3h5iMXfVGtEYwwTJrjvN3mxGt';
  } else {
    window.appConfig.REACT_APP_CLIENT_ID =
      'SIbCGvHJvbXeuL1nf5QGOQHKAznWGpCLeJnVZy3bF4NU8iLA';
  }
};

const handleAuthUrl = (): void => {
  if (isPre) {
    window.appConfig.REACT_APP_AUTH_URL =
      'https://test.customerportal.blackhorse.co.uk';
  } else if (isInt) {
    window.appConfig.REACT_APP_AUTH_URL =
      'https://int.customerportal.blackhorse.co.uk';
  } else if (isBld || isLocal) {
    window.appConfig.REACT_APP_AUTH_URL =
      'https://tnt01-odymsa-channels-web-ui.ew2.tnt01-bld.oncp.dev';
  } else if (isNftInt) {
    window.appConfig.REACT_APP_AUTH_URL =
      'https://tnt01-dev-channels-web-ui.ew2.tnt01-int.oncp.group';
  } else if (isNftPre) {
    window.appConfig.REACT_APP_AUTH_URL =
      'https://tnt01-dev-channels-web-ui.ew2.tnt01-pre.oncp.group';
  } else {
    window.appConfig.REACT_APP_AUTH_URL = liveURL;
  }
};

const handleUnauthUrl = (): void => {
  if (isPre) {
    window.appConfig.REACT_APP_UNAUTH_URL =
      'https://tnt01-odywss-channels-unauth-web-ui.ew2.tnt01-pre.oncp.group';
  } else if (isInt) {
    window.appConfig.REACT_APP_UNAUTH_URL =
      'https://int.customerportal.blackhorse.co.uk';
  } else if (isBld || isLocal) {
    window.appConfig.REACT_APP_UNAUTH_URL =
      'https://tnt01-odywss-channels-unauth-web-ui.ew2.tnt01-bld.oncp.dev';
  } else if (isNftInt) {
    window.appConfig.REACT_APP_UNAUTH_URL =
      'https://tnt01-dev-channels-unauth-web-ui.ew2.tnt01-int.oncp.group';
  } else if (isNftPre) {
    window.appConfig.REACT_APP_UNAUTH_URL =
      'https://tnt01-dev-channels-unauth-web-ui.ew2.tnt01-pre.oncp.group';
  } else {
    window.appConfig.REACT_APP_UNAUTH_URL = liveURL;
  }
};

const handleAuthProxy = (): void => {
  if (isBld || isLocal || isNftInt) {
    window.appConfig.REACT_APP_AUTH_PROXY = '';
  } else {
    window.appConfig.REACT_APP_AUTH_PROXY = '/channels/auth/v1';
  }
};

const handleUnauthProxy = (): void => {
  if (isBld || isLocal || isNftInt) {
    window.appConfig.REACT_APP_UNAUTH_PROXY = '';
  } else {
    window.appConfig.REACT_APP_UNAUTH_PROXY = '/channels/unauth/v1';
  }
};

const handleAuthZ = (): void => {
  if (isInt) {
    window.appConfig.REACT_APP_AUTH_Z =
      'https://atlas-int-01-channel-api.lloydsbanking.com';
  } else if (isPre) {
    window.appConfig.REACT_APP_AUTH_Z =
      'https://atlas-pre-01-channel-api.lloydsbanking.com';
  } else if (isBld || isLocal) {
    window.appConfig.REACT_APP_AUTH_Z =
      'https://tnt01-odymsa-mock-service.ew2.tnt01-bld.oncp.dev';
  } else {
    window.appConfig.REACT_APP_AUTH_Z =
      'https://atlas-channel-api.lloydsbanking.com';
  }
};
const handleImageURL = (): void => {
  if (isInt || isPre || isNftInt || isNftPre || isBld || isLocal) {
    window.appConfig.REACT_APP_IMAGE_URL =
      'https://test.customerportal.blackhorse.co.uk/api/contentservices/api/contentstream/contentRepository/mta/blackhorse/images/black-horse.png';
  } else {
    window.appConfig.REACT_APP_IMAGE_URL = `${liveURL}/api/contentservices/api/contentstream/contentRepository/mta/blackhorse/images/black-horse.png`;
  }
};
const handleWSSURL = (): void => {
  if (isInt || isPre || isNftInt || isNftPre || isBld || isLocal) {
    window.appConfig.REACT_APP_WSS_URL =
      'https://blackhorse-motor-finance-wss-strategic-devtest02.ia.lloydsbanking.cloud';
  } else {
    window.appConfig.REACT_APP_WSS_URL = liveURL;
  }
};
export const handleEnvVariables = (): void => {
  handleTagEnv();
  handleApiUrl();
  handleClientId();
  handleAuthUrl();
  handleUnauthUrl();
  handleAuthProxy();
  handleUnauthProxy();
  handleAuthZ();
  handleImageURL();
  handleWSSURL();
};
