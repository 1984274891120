import React, { ReactElement, useEffect, useRef } from 'react';

import {
  Container,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Hr,
  Icon,
  Link,
  List,
  ListItem,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useTheme } from 'styled-components';

import { HelpCentreContent, HelpCentreProps } from './helpCentre.config';
import {
  StyledText,
  StyledListDiv,
  BorderedDiv,
  StyledPrevContentGroup,
  StyledPreviousLink,
  StyledHeader,
  StyledDiv,
} from './helpCentreMta.styled';
import BulletIcon from '../../assets/icons/bulletIcon';
import MailIcon from '../../assets/icons/mailIcon';
import PhoneIcon from '../../assets/icons/phoneIcon';
import HelpCentreQns from '../../components/helpCentre/helpCentreQns';
import dataQaIds from '../../dataModel/dataQaIds';
import { dataLayer } from '../../utils/dataLayer';

export default function HelpCentreMta({
  setCreateAccountFAQ,
  createAccountFAQ,
}: HelpCentreProps): ReactElement {
  const theme: any = useTheme();
  const {
    mainHeading,
    headingLogin,
    headingReg,
    headingText,
    previousTitle,
    headingMoreHelp,
    moreHelpContent,
    headingTalkToUs,
    talkToUsNum,
    talkToUsTime,
    talkToUsSupport,
    headingWriteTous,
    talkToUsContent,
    writeToBrand,
    writeToStreet,
    writeToCity,
    writeToCode,
    writeToUsContent,
    helpCentreLoginQns,
    helpCentreRegistrationQns,
  } = useContent<HelpCentreContent>();
  const handleOnNavigateBack = () => {
    window.history.back();
    setCreateAccountFAQ(false);
  };
  const scrollToCreateAcc = useRef(null);

  useEffect(() => {
    if (createAccountFAQ) {
      const target = scrollToCreateAcc.current.getBoundingClientRect();
      window.scrollTo({
        top: window.scrollY + target.top - 100,
        behavior: 'smooth',
      });
    }
    window.appConfig.JOURNEY_NAME = 'Help Centre';
    window.appConfig.JOURNEY_STEP_NAME = 'Help Centre Landing Page';
    window.appConfig.PAGE_ROLE = 'Help Advice';
    dataLayer();
  });
  return (
    <Container>
      <StyledPrevContentGroup
        marginBottom="none"
        className="navigatePreviousLink"
      >
        <StyledPreviousLink
          className="linkBtn"
          onClick={handleOnNavigateBack}
          data-testid="navigatePreviousLink"
          data-qa-id={dataQaIds.helpCentre.previousLink}
        >
          <Icon name="chevron-left" color="brand" />
          {previousTitle}
        </StyledPreviousLink>
      </StyledPrevContentGroup>
      <StyledHeader marginLeft="02">
        <Heading
          as="h1"
          size="s4"
          marginLeft="none"
          marginBottom="none"
          aria-level={2}
        >
          {headingText}
        </Heading>
      </StyledHeader>

      <Grid alignX="center">
        <GridItem />
        <GridItem lg={8} md={10} sm={12}>
          <GridItem>
            <ContentGroup>
              <Heading as="h1" size="s5" marginBottom="07">
                {mainHeading}
              </Heading>
              <Heading as="h5" size="s4" marginBottom="06">
                {headingLogin}
              </Heading>
              <StyledDiv>
                <HelpCentreQns helpCentreQns={helpCentreLoginQns} />
              </StyledDiv>
            </ContentGroup>
            <ContentGroup>
              <Heading as="h5" size="s4" marginBottom="06">
                {headingReg}
              </Heading>
              <StyledDiv>
                <HelpCentreQns helpCentreQns={helpCentreRegistrationQns} />
              </StyledDiv>
            </ContentGroup>
          </GridItem>
        </GridItem>
        <GridItem lg={12} md={12} sm={12}>
          <Hr />
          <GridItem>
            <Heading as="h5" size="s3" marginTop="06">
              {headingMoreHelp}
            </Heading>
            <Paragraph size="s2">{moreHelpContent}</Paragraph>
            <BorderedDiv>
              <StyledListDiv>
                <Heading as="h5" size="s3">
                  {headingTalkToUs}
                </Heading>
                <List>
                  <ListItem
                    icon={<PhoneIcon colour={theme.color_text_default_1} />}
                  >
                    <Link
                      className="phoneNumber"
                      href={`tel:${talkToUsNum}`}
                      data-qa-id="qwq"
                    >
                      {talkToUsNum}
                    </Link>
                  </ListItem>
                  <ListItem
                    icon={<BulletIcon colour={theme.color_text_default_1} />}
                  >
                    {talkToUsTime}
                  </ListItem>
                  <ListItem
                    icon={<BulletIcon colour={theme.color_text_default_1} />}
                  >
                    {talkToUsSupport}
                  </ListItem>
                </List>
                <StyledText size="s1" align="justify">
                  {talkToUsContent}
                </StyledText>
              </StyledListDiv>

              <StyledListDiv>
                <Heading size="s3">{headingWriteTous}</Heading>
                <List>
                  <ListItem
                    icon={<MailIcon colour={theme.color_text_default_1} />}
                  >
                    {writeToBrand}
                    <br />
                    {writeToStreet}
                    <br />
                    {writeToCity}
                    <br />
                    {writeToCode}
                  </ListItem>
                </List>
                <StyledText size="s1" align="justify">
                  {writeToUsContent}
                </StyledText>
              </StyledListDiv>
            </BorderedDiv>
          </GridItem>
        </GridItem>
        <GridItem />
      </Grid>
    </Container>
  );
}
