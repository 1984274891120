import axios from 'axios';

import { ApiEndpoints } from '../constants/apiEndpoints';
import { isInt } from '../utils/handleEnvVariables';

const config = {
  headers: {
    'content-Type': 'application/json',
    'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
    'x-lbg-user-id': '12',
  },
};

export const createPassword = (
  id,
  password: string,
  contractId,
  emailID,
  bpid,
  additionalConfig,
  additionalParams,
): Promise<object> =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${window.appConfig.REACT_APP_API_URL}${window.appConfig.REACT_APP_AUTH_PROXY}/mta/api/v1/${ApiEndpoints.AUTH_USER}/${id}`,
        {
          password,
          userGroup: ['BLH'],
          userStatus: 'ACTIVE',
          customerId: id,
          contractId,
          isRegistration: true,
          returnphone: '03448 248 888',
          openinghrs: 'Mon-Fri 8:30am-6pm, Sat 9am-1pm',
          accesscontact: '0800 302 9155',
          brandeMail: '',
          helpcentreurl: 'www.blackhorse_helpcentre.co.uk',
          businessPartnerId: bpid,
          toEmail: emailID,
          brandname: 'Blackhorse',
          contentaction: 'Customer successfully registers',
          ...additionalParams,
        },
        {
          headers: {
            ...config.headers,
            'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
            ...additionalConfig,
            ...(isInt ? { 'x-lbg-env': 'int1' } : {}),
          },
        },
      )
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
