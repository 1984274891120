import React, { ReactElement, useEffect } from 'react';

import { Container, Icon, GridItem } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { TermsAndConditionsContent } from './TermsAndConditions.config';
import {
  StyledBoldedParagraph,
  StyledInnerDiv,
  StyledGoBack,
  StyledBottomHr,
} from './termsAndConditions.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { dataLayer } from '../../utils/dataLayer';
import {
  StyledGrid,
  StyledPrevContentGroup,
  StyledPreviousLink,
  StyledSubHeading,
} from '../privacyPolicy/privacyPolicyMta.styled';

export default function TermsAndConditions(): ReactElement {
  const {
    TermsHeading,
    TermsWebsiteHeading,
    TermsShouldRead,
    TremsChangedContent,
    TradingDisclosure,
    TradingDisclosureTxt,
    TradingDisclosureSubTxt,
    BHHeading,
    BHRegistered,
    BHConduct,
    BHvat,
    BHLendingCode,
    ContactUsHeading,
    ContactUsTxt,
    TermsWhoCanUseHeading,
    TermsWhoCanUseContent,
    TermsServiceHeading,
    TermsServiceContent,
    TermsDisclaimerHeading,
    TermsProductDisclaimerContent,
    TermsCopyrightsHeading,
    TermsCopyrightsContent,
    TermsLiabilityHeading,
    TermsLiabilityUKContent,
    TermsLawAndJurisdictionHeading,
    TermsLawAndJurisdictionContent,
    goBackLink,
  } = useContent<TermsAndConditionsContent>();

  const handleOnNavigateBack = () => {
    window.history.back();
  };
  const isDeviceFound = useDeviceResizing();
  useEffect(() => {
    window.appConfig.JOURNEY_NAME = 'Terms and conditions';
    window.appConfig.JOURNEY_STEP_NAME = 'Terms and conditions';
    window.appConfig.PAGE_ROLE = 'Information';
    dataLayer();
  }, []);

  const handleOnParagraph = (
    isBold: boolean,
    txt: string,
    marginBottom?: any,
    marginTop?: any,
    isSubheading = false,
  ): ReactElement => (
    <StyledBoldedParagraph
      isSubheading={isSubheading}
      size="s2"
      marginTop={marginTop}
      marginBottom={marginBottom}
      isBold={isBold}
      dangerouslySetInnerHTML={{ __html: txt }}
    >
      {}
    </StyledBoldedParagraph>
  );
  const handleOnHeading = (
    isBold: boolean,
    isSubheading: boolean,
    heading: string,
    size: any,
    marginBottom?: any,
    marginTop?: any,
  ): ReactElement => (
    <StyledSubHeading
      isSubheading={isSubheading}
      isBold={isBold}
      size={size}
      marginBottom={marginBottom}
      marginTop={marginTop}
      aria-level={4}
    >
      {heading}
    </StyledSubHeading>
  );

  return (
    <Container marginBottom="05" padding="none">
      <StyledPrevContentGroup
        marginBottom="none"
        className="navigatePreviousLink"
      >
        <StyledPreviousLink
          className="linkBtn"
          onClick={handleOnNavigateBack}
          data-testid="navigatePreviousLink"
          data-qa-id={dataQaIds.helpCentre.previousLink}
        >
          <Icon name="chevron-left" color="brand" />
          Previous
        </StyledPreviousLink>
      </StyledPrevContentGroup>
      <StyledGrid>
        <GridItem lg={2} />
        <GridItem lg={8}>
          <StyledInnerDiv
            className={isDeviceFound.mobile ? 'mobileFound' : 'notMobile'}
          >
            {handleOnHeading(false, false, TermsHeading, 's5')}
            {handleOnParagraph(false, TermsWebsiteHeading)}
            {handleOnParagraph(false, TermsShouldRead)}
            {handleOnParagraph(false, TremsChangedContent)}
            {handleOnHeading(true, false, TradingDisclosure, 's3', '0')}
            {handleOnParagraph(false, TradingDisclosureTxt)}
            {handleOnParagraph(false, TradingDisclosureSubTxt)}
            {handleOnHeading(true, false, BHHeading, 's3', '0')}
            {handleOnParagraph(false, BHRegistered)}
            {handleOnParagraph(false, BHConduct)}
            {handleOnParagraph(false, BHvat)}
            {handleOnParagraph(false, BHLendingCode)}
            {handleOnHeading(true, false, ContactUsHeading, 's3', '0')}
            {handleOnParagraph(false, ContactUsTxt)}
            {handleOnHeading(true, false, TermsWhoCanUseHeading, 's3', '0')}
            {handleOnParagraph(false, TermsWhoCanUseContent)}
            {handleOnHeading(true, false, TermsServiceHeading, 's3', '0')}
            {handleOnParagraph(false, TermsServiceContent)}
            {handleOnHeading(true, false, TermsDisclaimerHeading, 's3', '0')}
            {handleOnParagraph(false, TermsProductDisclaimerContent)}
            {handleOnHeading(true, false, TermsCopyrightsHeading, 's3', '0')}
            {handleOnParagraph(false, TermsCopyrightsContent)}
            {handleOnHeading(true, false, TermsLiabilityHeading, 's3', '0')}
            {handleOnParagraph(false, TermsLiabilityUKContent)}
            {handleOnHeading(
              true,
              false,
              TermsLawAndJurisdictionHeading,
              's3',
              '0',
            )}
            {handleOnParagraph(false, TermsLawAndJurisdictionContent)}
          </StyledInnerDiv>
          <StyledBottomHr />
          <StyledGoBack>
            <LinkButton
              handleButtonClick={handleOnNavigateBack}
              testId="goBackLink"
            >
              {goBackLink}
            </LinkButton>
          </StyledGoBack>
        </GridItem>
        <GridItem />
      </StyledGrid>
    </Container>
  );
}
