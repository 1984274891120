import React, { ReactElement } from 'react';

import { GridItem, ContentGroup, Text, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import {
  ForgotPasswordContent,
  LoginAuthenticateProps,
} from './forgotPassword.config';
import { StyledInlineBox } from '../../components/appConfig/common.styled';
import PhoneLink from '../../components/phoneLink/PhoneLink';

export default function LoginAuthenticate({
  isCustomerIdentificationError,
  isNumberFound,
  phoneNumber,
  isLogin,
}: LoginAuthenticateProps): ReactElement {
  const {
    customerNotFoundHeading,
    customerNotFoundSubText,
    verifyText,
    authSubText,
    noContactFoundSubText,
    getPasscodeHeading,
  } = useContent<ForgotPasswordContent>();
  const handleText = (): string => {
    if ((isLogin && isNumberFound) || (isLogin && !isNumberFound))
      return getPasscodeHeading;
    if (!isLogin && !isNumberFound) return customerNotFoundHeading;
    return verifyText;
  };
  const handleAuthSubText = (): string => {
    const text = authSubText.trim().split('');
    text.pop();
    return text.join('');
  };
  return isCustomerIdentificationError ? (
    <GridItem>
      <Heading size="s4" marginBottom="02" aria-level={3}>
        {customerNotFoundHeading}
      </Heading>
      <Text size="s2">{customerNotFoundSubText}</Text>
    </GridItem>
  ) : (
    <>
      <GridItem>
        <ContentGroup marginBottom="02">
          <Text size="s4">{handleText()}</Text>
        </ContentGroup>
      </GridItem>
      <GridItem>
        {isLogin && !isNumberFound && (
          <ContentGroup marginBottom="05" marginTop="none">
            <Text size="s2">{handleAuthSubText()}</Text>
          </ContentGroup>
        )}
        <ContentGroup marginBottom="05" marginTop="none">
          <Text size="s2">
            {isNumberFound ? authSubText : noContactFoundSubText}
          </Text>
        </ContentGroup>
        {isNumberFound && (
          <ContentGroup marginBottom="05" marginTop="none">
            <Text size="s2" color="subdued" data-cs-mask>
              {phoneNumber}
            </Text>
          </ContentGroup>
        )}
        {!isNumberFound && (
          <StyledInlineBox>
            <PhoneLink />
          </StyledInlineBox>
        )}
      </GridItem>
    </>
  );
}
