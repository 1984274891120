import React, { ReactElement } from 'react';

import { Paragraph } from '@constellation/core';

import { IHelpCentreQuestionsProps } from './helpCentreQns.config';
import {
  StyledAccordion,
  StyledContentGroup,
  StyledDiv,
} from './helpCentreQns.styled';

export default function HelpCentreQns({
  helpCentreQns,
}: IHelpCentreQuestionsProps): ReactElement {
  const getQuestionsList = helpCentreQns.map((item: any, index: number) => {
    return (
      <StyledDiv key={`answers${index + 1}`}>
        <StyledAccordion label={item.hcQuestions} variation="inpage1">
          <Paragraph>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: item.hcQnsAnswer }}
            />{' '}
          </Paragraph>
        </StyledAccordion>
      </StyledDiv>
    );
  });
  return (
    <StyledContentGroup data-testid="helpCentreQns">
      {getQuestionsList}
    </StyledContentGroup>
  );
}
