import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { TextField } from '@constellation/core';
import { ContentProvider, useContent } from '@interstellar/react-app-content';
import { useSelector } from 'react-redux';

import {
  PostcodeContent,
  PostcodeFieldProps,
  PostcodeFieldPropsOuter,
} from './postcodeField.config';
import { REGEX, passwordPattern } from '../../constants/REGEX';
import content from '../../content';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { isEmptyString } from '../../utils/isEmptyString';

function PostcodeFieldInner({
  handleOnPostCodeError,
  isContinueClicked,
  handleOnPostCode,
}: PostcodeFieldProps): ReactElement {
  const { postcode } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const [postCodeErrMsg, setPostCodeErrMsg] = useState('');
  const [postCode, setPostCode] = useState(postcode);

  const { postcodeError, placeholder } = useContent<PostcodeContent>();
  useEffect(() => {
    if (isContinueClicked && isEmptyString(postCode)) {
      setPostCodeErrMsg(postcodeError);
      handleOnPostCodeError(true);
    } else if (!isEmptyString(postCode) && isEmptyString(postCodeErrMsg)) {
      handleOnPostCodeError(false);
      handleOnPostCode(postCode || postcode);
    }
  }, [
    isContinueClicked,
    postCode,
    postcodeError,
    handleOnPostCodeError,
    handleOnPostCode,
    postcode,
    postCodeErrMsg,
  ]);
  const checkOnPostCodeValidate = (value: string): boolean => {
    const postCodeRegex = new RegExp(REGEX.POSTCODE_MATCH);
    return postCodeRegex.test(value);
  };

  const handleOnPCSetErrMsg = (value: string): void => setPostCodeErrMsg(value);

  const handleOnPCErrMsg = (value: string): void => {
    if (checkOnPostCodeValidate(value)) {
      setPostCode(value);
      handleOnPCSetErrMsg('');
      if (handleOnPostCodeError) {
        handleOnPostCodeError(false);
      }
    } else if (isEmptyString(value) || !checkOnPostCodeValidate(value)) {
      handleOnPCSetErrMsg(postcodeError);
      if (handleOnPostCodeError) {
        handleOnPostCodeError(true);
      }
    }
  };

  const handleOnPCBlurEvt = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    let { value } = event.target;
    value = value.trim();
    handleOnPCErrMsg(value);
  };

  const handlePCKeyDown = (event: KeyboardEvent<HTMLInputElement>): boolean => {
    const { value } = event.target as HTMLInputElement;
    if (passwordPattern.containSpecialCharacter.indexOf(event.key) !== -1) {
      event.preventDefault();
      return false;
    }
    handleOnPCSetErrMsg('');
    checkOnPostCodeValidate(value);
    return true;
  };
  const handlePCChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPostCode(event.target.value.toUpperCase());
    handleOnPostCode(event.target.value.toUpperCase());
  };

  return (
    <TextField
      label=""
      name="postcodeField"
      placeholder={placeholder}
      error={postCodeErrMsg}
      data-qa-id={dataQaIds.loginAndRegistration.postCodeField}
      onKeyDown={handlePCKeyDown}
      onChange={handlePCChange}
      onBlur={handleOnPCBlurEvt}
      marginBottom="none"
      value={postCode}
      data-cs-mask
    />
  );
}

export default function PostcodeField({
  handleOnPostCodeError,
  isContinueClicked,
  handleOnPostCode,
  brand,
}: PostcodeFieldPropsOuter) {
  return (
    <ContentProvider value={content({ brand }).postcode}>
      <PostcodeFieldInner
        isContinueClicked={isContinueClicked}
        handleOnPostCode={handleOnPostCode}
        handleOnPostCodeError={handleOnPostCodeError}
      />
    </ContentProvider>
  );
}
