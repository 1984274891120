import React, { ReactElement } from 'react';

import { ContentGroup, Grid, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  IAgreementSelectorProps,
  MultiAgreementContent,
} from './multiAgreement.config';
import {
  ClickableArea,
  StyledBox,
  StyledTextContainer,
  StyledSuplimentaryText,
  StyledFlex,
  StyledCarImage,
  StyledCarImageContainer,
  StyledChevronIcon,
} from './multiAgreement.styled';
import CarImage from '../../assets/icons/carImage';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { getCookie } from '../../utils/cookie';
import { isInt, isLive } from '../../utils/handleEnvVariables';
import { isEmptyString } from '../../utils/isEmptyString';
import { isYear } from '../../utils/isYear';
import { redirectURL } from '../../utils/redirectURL';

export default function AgreementSelectorCTA({
  accessToken,
  emailAddress,
}: IAgreementSelectorProps): ReactElement {
  const { completedLabel } = useContent<MultiAgreementContent>();
  const theme: any = useTheme();
  const { customerData } = useSelector(
    (state: RootState) => state.MultiAgreementReducer,
  );
  const deviceResizing = useDeviceResizing();
  const handleDeepLink = () => {
    if (window.location.search.includes('redirectTo'))
      return `&redirectTo=${
        window.location.search.split('?')[2].split('=')[1]
      }`;
    return '';
  };
  const handleQueryParam = (cookie: any): string => {
    if (isInt) return `&authorization=${cookie}`;
    return '';
  };
  const handleContractId = (
    contractNumber: string,
    redirectLink: string,
    customerId: string,
  ): void => {
    if (!isEmptyString(customerId)) {
      const cookie = getCookie('Authorization');
      window.location.replace(
        `${redirectLink}blackhorse/dashboard#/myaccount?customerId=${customerId}&accountNumber=${contractNumber}&isArchived=false&username=${emailAddress}${handleQueryParam(
          cookie,
        )}${handleDeepLink()}
        `,
      );
    } else {
      if (window.location.search.includes('redirectTo'))
        window.sessionStorage.setItem(
          'redirectTo',
          window.location.search.split('?')[2].split('=')[1],
        );
      redirectURL(
        'account-home',
        redirectLink,
        `auth_token=${accessToken}&contractNumber=${contractNumber}&email=${emailAddress}`,
      );
    }
  };

  const generateCarUrl = (item: any): string => {
    if (isYear(item.vehicleMakeModel.split(' ')[0])) {
      return `https://cdn.imagin.studio/getImage?customer=lloydsbank&angle=23&zoomType=fullscreen&make=${
        !isEmptyString(item.dvlaApiMake)
          ? item.dvlaApiMake
          : item.vehicleMakeModel.split(' ')[1]
      }&modelFamily=${item.vehicleMakeModel
        .split(' ')
        .slice(2, 4)
        .join(' ')}&modelYear=${
        item.vehicleMakeModel.split(' ')[0]
      }&paintdescription=${item.colour}`;
    }
    return `https://cdn.imagin.studio/getImage?customer=lloydsbank&angle=23&zoomType=fullscreen&make=${
      !isEmptyString(item.dvlaApiMake)
        ? item.dvlaApiMake
        : item.vehicleMakeModel.split(' ')[0]
    }&modelFamily=${item.vehicleMakeModel
      .split(' ')
      .slice(1, 4)
      .join(' ')}&modelYear=${item.yearOfManufacture}&paintdescription=${
      item.colour
    }`;
  };
  const carImageWidth = () => {
    if (deviceResizing.mobile) {
      return '120px';
    }
    return '140px';
  };
  return (
    <>
      {customerData.map((item, i) => (
        <ClickableArea
          key={`multiagreement-${i + 1}`}
          onClick={() =>
            handleContractId(
              item.contractId,
              item.redirectLink,
              item.customerId,
            )
          }
          data-testid={`agreementContainer${i + 1}`}
          data-qa-id={`${dataQaIds.multiAgreement.agreementSelector}-${i + 1}`}
          tabIndex={0}
        >
          <StyledBox marginBottom={customerData.length - 1 === i ? '07' : '03'}>
            <ContentGroup marginBottom="none">
              <Text
                size={deviceResizing.mobile ? 's2' : 's3'}
                weight="bold"
                marginBottom={deviceResizing.mobile ? '01' : '02'}
                color={item.isArchived ? 'subdued' : 'normal'}
              >
                {item.vehicleMakeModel}
              </Text>
              <StyledTextContainer>
                <StyledSuplimentaryText
                  color="subdued"
                  size={deviceResizing.mobile ? 's1' : 's2'}
                  weight={item.isArchived ? 'bold' : 'normal'}
                  marginRight={!deviceResizing.mobile ? '04' : 'none'}
                  isArchived={item.isArchived}
                >
                  {item.isArchived ? completedLabel : item.contractId}
                </StyledSuplimentaryText>
                <Text
                  color="subdued"
                  size={deviceResizing.mobile ? 's1' : 's2'}
                  marginBottom={deviceResizing.mobile ? '01' : 'none'}
                  marginTop={deviceResizing.mobile ? '01' : 'none'}
                >
                  {item.vehicleReg}
                </Text>
              </StyledTextContainer>
            </ContentGroup>
            <StyledFlex>
              {isLive ? (
                <StyledCarImage
                  isArchived={item.isArchived}
                  src={generateCarUrl(item)}
                  width={carImageWidth()}
                />
              ) : (
                <StyledCarImageContainer isArchived={item.isArchived}>
                  <CarImage
                    colourPrimary={theme.color_icon_default_1}
                    colourSecondary={theme.color_icon_default_2}
                  />
                </StyledCarImageContainer>
              )}
              <Grid alignY="center">
                <ContentGroup
                  marginBottom="none"
                  marginLeft="02"
                  marginRight="03"
                  marginTop="none"
                >
                  <StyledChevronIcon color="brand" />
                </ContentGroup>
              </Grid>
            </StyledFlex>
          </StyledBox>
        </ClickableArea>
      ))}
    </>
  );
}
