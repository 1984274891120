import React, { ReactElement } from 'react';

import {
  Container,
  Grid,
  GridItem,
  useWindowSize,
  pxToRem,
} from '@constellation/core';
import { NavLink } from '@interstellar/react-app-routing';
import { useTheme } from 'styled-components';

import { StyledHeader } from './HeaderComponent.styled';
import * as routes from '../../routes/manifest';

export default function HeaderComponent(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();

  const { width: windowWidth } = useWindowSize();

  const isNarrow = pxToRem(windowWidth) < parseFloat(theme.breakpoint_lg);
  const isWide = !isNarrow;

  const Logo = isWide ? theme.assets.logo.wide : theme.assets.logo.base;

  return (
    <StyledHeader>
      <Container>
        <Grid alignY="center">
          <GridItem xs={4}>
            <NavLink to={routes.WelcomePage} title="logo">
              <Logo
                style={{
                  height: isWide
                    ? theme.header_logo_height_big
                    : theme.header_logo_height,
                  width: isWide
                    ? theme.header_logo_width_big
                    : theme.header_logo_width,
                }}
              />
            </NavLink>
          </GridItem>
          <GridItem xs={6} />
          <GridItem xs={2} />
        </Grid>
      </Container>
    </StyledHeader>
  );
}
