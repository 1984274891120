import common from './common';
import { AppContent } from '../AppContent';

const multiAgreement: AppContent['multiAgreement'] = {
  heading: 'You have {{number}} vehicles in your garage.',
  completedLabel: 'Completed',
  ...common,
};

export default multiAgreement;
