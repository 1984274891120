import React, { ReactElement } from 'react';

import { Grid, Text } from '@constellation/core';
import { ChevronRight } from '@constellation/core/icons';
import { useNavigate } from '@interstellar/react-app-routing';

import { ICTAProps } from './Cta.config';
import {
  StyledContainer,
  StyledGridItem,
  StyledIcon,
  StyledNoPadding,
  StyledSubtitle,
  StyledIconGridItem,
} from './styledCtaComponent';
import { StyledLink } from '../appLayout/Applayout.styled';

export default function CTA({
  title,
  subTitle,
  icon = false,
  navigateRoute,
  hasTopSpacing = false,
  iconPosition = 'none',
  hasBottomSpacing = false,
  dataQaId = '',
  testId = '',
  titleSize = 's2',
}: ICTAProps): ReactElement {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(navigateRoute);
  };
  return (
    <StyledLink
      onClick={handleNavigate}
      data-qa-id={dataQaId}
      data-testid={testId}
    >
      <StyledContainer
        marginTop={hasTopSpacing ? '04' : 'none'}
        marginBottom={hasBottomSpacing ? '06' : 'none'}
      >
        <Grid alignY="center" alignX="justify">
          {iconPosition === 'side' && (
            <StyledIconGridItem data-test-id="test-icon" md={1} lg={1} sm={1}>
              {icon}
            </StyledIconGridItem>
          )}

          <StyledNoPadding
            xs={iconPosition === 'side' ? 10 : 11}
            lg={iconPosition === 'side' ? 10 : 11}
            md={iconPosition === 'side' ? 10 : 11}
          >
            {iconPosition === 'top' && icon}
            <StyledGridItem>
              <Text weight="bold" size={titleSize}>
                {title}
              </Text>
            </StyledGridItem>
            <StyledSubtitle
              className={iconPosition === 'top' ? 'addTruncation' : ''}
            >
              <Text size="s1" color="subdued">
                {subTitle}
              </Text>
            </StyledSubtitle>
          </StyledNoPadding>
          <StyledIcon xs={1}>
            <ChevronRight size="s2" color="brand" />
          </StyledIcon>
        </Grid>
      </StyledContainer>
    </StyledLink>
  );
}
