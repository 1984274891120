import { DateProps } from '../../components/appConfig/AppConfig';
import { ApiAuthResponse } from '../../routes/registration/Registration.config';
import {
  ApiCalledInterface,
  AuthTokenInterface,
  ContactNumberFoundInterface,
  CustomerAgreementNumberInterface,
  CustomerMtaDataInterface,
  CustomerWssDataInterface,
  DOBPostcodeContractsInterface,
  HasRegistrationNumberInterface,
  PasswordFailureInterface,
  PasswordSetInterface,
  UpdateDOBPostcodeInterface,
} from '../action/registration.action';
import { UpdateEmailAPIErrorInterface } from '../action/updateEmail.action';

export interface IRegistrationState {
  isUpdateEmail: boolean;
  postcode: string;
  dob: DateProps;
  customerData: ApiAuthResponse[];
  constraints: {
    isDetailsFound?: boolean;
    isApiFailed?: boolean;
    isMoreThanOneUser?: boolean;
  };
  agreementNumber: string;
  userToken: string;
  wssCustomerContactInfo: string;
  hasAllRegistrationNumbers: boolean;
  isContactNumberFound: boolean;
  isApiCalled: boolean;
  authToken: string;
  hasPasswordSet: boolean;
  passwordFailureOrigin: string;
}
const responseData = {
  wssCustomerId: '',
  isRegistered: true,
  contractId: '',
  isWSS: false,
  emailAddress: '',
  mtaCustomerId: '',
  obscuredPhoneNumber: '',
  wssCustomerID: '',
};
const initialState = {
  isUpdateEmail: false,
  postcode: '',
  dob: { day: '', month: '', year: '' },
  customerData: [responseData],
  constraints: {
    isDetailsFound: true,
    isApiFailed: false,
    isMoreThanOneUser: false,
  },
  agreementNumber: '',
  userToken: '',
  wssCustomerContactInfo: '',
  hasAllRegistrationNumbers: false,
  isContactNumberFound: true,
  isApiCalled: false,
  authToken: '',
  hasPasswordSet: false,
  passwordFailureOrigin: '',
};

export const RegistrationReducer = (
  state: IRegistrationState = initialState,
  action: Partial<UpdateEmailAPIErrorInterface> &
    Partial<UpdateDOBPostcodeInterface> &
    Partial<DOBPostcodeContractsInterface> &
    Partial<CustomerMtaDataInterface> &
    Partial<CustomerWssDataInterface> &
    Partial<CustomerAgreementNumberInterface> &
    Partial<HasRegistrationNumberInterface> &
    Partial<ContactNumberFoundInterface> &
    Partial<ApiCalledInterface> &
    Partial<AuthTokenInterface> &
    Partial<PasswordSetInterface> &
    Partial<PasswordFailureInterface> = {
    type: '',
    ...initialState,
  },
): IRegistrationState => {
  switch (action.type) {
    case 'UPDATE_EMAIL_API_ERROR':
      return {
        ...state,
        isUpdateEmail: action.isUpdateEmail,
      };
    case 'UPDATE_POSTCODE_DOB':
      return {
        ...state,
        postcode: action.resetValues ? initialState.postcode : action.postcode,
        dob: action.resetValues ? initialState.dob : action.dob,
      };
    case 'POSTCODE_DOB_DATA':
      return {
        ...state,
        customerData:
          action.customerData.length > 0
            ? action.customerData
            : initialState.customerData,
        constraints: action.constraints,
      };
    case 'CUSTOMER_DATA':
      return {
        ...state,
        customerData: action.customerData,
      };
    case 'CUSTOMER_AGREEMENT_NUMBER':
      return {
        ...state,
        agreementNumber: action.agreementNumber,
      };
    case 'HAS_REGISTRATION_NUMBERS':
      return {
        ...state,
        hasAllRegistrationNumbers: action.hasAllRegistrationNumbers,
      };
    case 'CONTACT_NUMBER_FOUND':
      return {
        ...state,
        isContactNumberFound: action.isContactNumberFound,
      };
    case 'API_CALLED':
      return {
        ...state,
        isApiCalled: action.isApiCalled,
      };
    case 'AUTH_TOKEN':
      return {
        ...state,
        authToken: action.authToken,
      };
    case 'PASSWORD_SET':
      return {
        ...state,
        hasPasswordSet: action.hasPasswordSet,
      };
    case 'PASSWORD_FAILURE':
      return {
        ...state,
        passwordFailureOrigin: action.passwordFailureOrigin,
      };

    default:
      return state;
  }
};
