import React, { ReactElement, useState } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import { Routes, Route } from '@interstellar/react-app-routing';

import CookiePolicy from './cookiePolicy/cookiePolicy';
import ForgotEmail from './forgotEmail/forgotEmail';
import ForgotPassword from './forgotPassword/forgotPassword';
import HelpCentre from './helpCentre/helpCentreMta';
import * as routes from './manifest';
import MultiAgreements from './multiAgreements/multiAgreement';
import NotFound from './notFound/NotFound';
import PrivacyPolicy from './privacyPolicy/privacyPolicyMta';
import Registration from './registration/Registration';
import SetMarketingPreference from './setMarketingPreferences/setMarketingPreference';
import TermsAndConditions from './termsAndConditions/termsAndConditions';
import TestPage from './testPage/testPage';
import WelcomePage from './welcomePage/WelcomePage';
import UpdatePasswordWss from './wssUpdatePassword/updatePasswordWss';
import { BrandProps } from '../components/appConfig/AppConfig';
import content from '../content';

function App({ brand }: BrandProps): ReactElement {
  const [passwordValue, setPasswordValue] = useState('');
  const [createAccountFAQ, setCreateAccountFAQ] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authToken, setAuthToken] = useState('');
  return (
    <Routes>
      <Route
        path={routes.WelcomePage}
        element={
          <ContentProvider value={content({ brand }).welcome}>
            <WelcomePage />
          </ContentProvider>
        }
      />
      <Route
        path="*"
        element={
          <ContentProvider value={content({ brand }).notfound}>
            <NotFound />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Registration}
        element={
          <ContentProvider value={content({ brand }).registration}>
            <Registration
              brand={brand}
              passwordValue={passwordValue}
              setPasswordValue={setPasswordValue}
              setCreateAccountFAQ={setCreateAccountFAQ}
            />
          </ContentProvider>
        }
      />
      <Route
        path={routes.MultipleAgreements}
        element={
          <ContentProvider value={content({ brand }).multiAgreement}>
            <MultiAgreements />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ForgotEmail}
        element={
          <ContentProvider
            value={{
              ...content({ brand }).forgotEmail,
              ...content({ brand }).registration,
            }}
          >
            <ForgotEmail brand={brand} />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ForgotPassword}
        element={
          <ContentProvider value={content({ brand }).forgotPassword}>
            <ForgotPassword
              brand={brand}
              isAuthenticated={isAuthenticated}
              setIsAuthenticated={setIsAuthenticated}
              authToken={authToken}
              setAuthToken={setAuthToken}
            />
          </ContentProvider>
        }
      />
      <Route
        path={routes.HelpCentre}
        element={
          <ContentProvider value={content({ brand }).helpCentre}>
            <HelpCentre
              setCreateAccountFAQ={setCreateAccountFAQ}
              createAccountFAQ={createAccountFAQ}
            />
          </ContentProvider>
        }
      />
      <Route
        path={routes.TermsAndConditions}
        element={
          <ContentProvider value={content({ brand }).termsAndConditions}>
            <TermsAndConditions />
          </ContentProvider>
        }
      />
      <Route
        path={routes.CookiePolicy}
        element={
          <ContentProvider value={content({ brand }).cookiePolicy}>
            <CookiePolicy />
          </ContentProvider>
        }
      />
      <Route
        path={routes.PrivacyPolicy}
        element={
          <ContentProvider value={content({ brand }).privacyPolicy}>
            <PrivacyPolicy />
          </ContentProvider>
        }
      />
      <Route path={routes.TestPage} element={<TestPage />} />
      <Route
        path={routes.Marketingpreferences}
        element={
          <ContentProvider value={content({ brand }).marketingPreferences}>
            <SetMarketingPreference />
          </ContentProvider>
        }
      />
      <Route
        path={routes.SetPassword}
        element={
          <ContentProvider value={content({ brand }).setupPassword}>
            <UpdatePasswordWss
              setAuthToken={setAuthToken}
              authToken={authToken}
            />
          </ContentProvider>
        }
      />
    </Routes>
  );
}

export default App;
