export enum ApiEndpoints {
  AUTH = 'auth',
  AUTH_CONTRACTS = 'auth/contracts',
  AUTH_USER = 'authLite/users',
  AUTHLITE_SMS_SENDER = 'authLite/sms-sender',
  AUTHLITE_SMS_OTP = 'authLite/sms-otp',
  CONFIRM_EMAIL = 'confirmEmail',
  UPDATE_EMAIL = 'updateEmail',
  CONTRACT_CUSTOMERS = 'customers',
  LOGIN = 'login',
  WSS_MTA = 'auth/accounts',
  VALIDATE_CONTRACTS = 'contracts/validateContract',
  AUTH_Z = 'authz/v2/authorize',
  AUTH_Z_TOKEN = 'authz/v2/token',
  AUTH_USER_UPDATE = 'authLite/usersUpdate',
  AUTH_MULTI_CONTRACTS = 'customers/contracts',
  REGISTRATION = 'auth/registration',
  WSSUPDATEPASSWORD = `${LOGIN}/updatePassword`,
  CONFIRM_ACCOUNT = 'confirmAccount',
  FORGOT_EMAIL = 'auth/forgotEmail',
  CHECK_EMAIL = `${AUTH}/checkEmail`,
  FORGOT_PASSWORD_EMAIL = `${AUTH}/forgotPassword`,
}
