import {
  ILoginCredentials,
  ILoginInfo,
  ILoginModalInfo,
  IOldPasswordProps,
} from '../action/login.action';

export interface ILoginState {
  emailAddress: string;
  loginConstraints: {
    isLoginAttemptPrevented: boolean;
    isLoginAttemptExceeded: boolean;
    isRegistered: boolean;
  };
  isLoginCredentials: boolean;
  agreements: any[];
  oldPassword: string;
}
const initialState = {
  emailAddress: '',
  loginConstraints: {
    isLoginAttemptPrevented: false,
    isLoginAttemptExceeded: false,
    isRegistered: true,
  },
  isLoginCredentials: false,
  agreements: [],
  oldPassword: '',
};

export const LoginReducer = (
  state: ILoginState = initialState,
  action: Partial<ILoginInfo> &
    Partial<ILoginModalInfo> &
    Partial<ILoginCredentials> &
    Partial<IOldPasswordProps> = {
    type: '',
    ...initialState,
  },
): ILoginState => {
  if (action.type === 'FORGOT_EMAIL') {
    return {
      ...state,
      emailAddress: action.emailAddress || '',
    };
  }
  if (action.type === 'LOGIN_MODAL_CONSTRAINTS') {
    return {
      ...state,
      loginConstraints: action.loginConstraints,
    };
  }
  if (action.type === 'LOGIN_CREDENTIALS') {
    return {
      ...state,
      isLoginCredentials: action.isLoginCredentials,
    };
  }
  if (action.type === 'OLD_PASSWORD') {
    return {
      ...state,
      oldPassword: action.oldPassword,
    };
  }
  return state;
};
