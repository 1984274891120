import { ActionInterface } from '../../components/appConfig/AppConfig';
import { IApiResponseProps } from '../../routes/multiAgreements/multiAgreement.config';

export interface MultiAgreementInterface extends ActionInterface {
  customerData: IApiResponseProps[];
}

export const MultiAgreementAction = (
  customerData: IApiResponseProps[],
): MultiAgreementInterface => ({
  type: 'CUSTOMER_DATA',
  customerData,
});
