export enum FormSubSteps {
  PERSONAL_DETAILS = 'Personal details',
  MULTI_AGREEMENT = 'Multi agreement',
  CHANGE_EMAIL_ADDRESS = 'Change Email Address',
  CONFIRM_EMAIL_ADDRESS = 'Confirm Email Address',
  SEND_OTP = 'Send otp',
  VERIFY_OTP = 'Verify otp',
  CREATE_PASSWORD = 'Create password',

  ENTER_REGISTRATION_NUMBER = 'Enter Registration Number',
  ENTER_AGREEMENT_NUMBER = 'Enter Agreement Number',
}
