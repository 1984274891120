import { List, ListItem } from '@constellation/core';
import styled from 'styled-components';

export const StyledContentList = styled(List)`
  > li span:first-child,
  > li span span:last-child {
    left: 0 !important;
    pointer-events: all !important;
  }
  > li:last-child {
    margin-bottom: ${({ theme }) => theme.spacing_04} !important;
  }
`;
export const StyledFooterList = styled(StyledContentList)`
  > li span,
  > li span svg {
    color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  }
  > li span svg {
    height: ${({ theme }) => theme.spacing_04} !important;
    width: ${({ theme }) => theme.spacing_04} !important;
    padding-top: 10px;
  }
  > li:last-child {
    margin-top: ${({ theme }) => theme.spacing_04} !important;
    margin-bottom: 0px !important;
  }
`;
export const StyledHelpListItem = styled(ListItem)`
  margin-bottom: 0px !important;
`;
export const StyledInnerDiv = styled('div')`
  > span,
  p,
  ul > li span svg,
  li > span {
    color: ${({ theme }) => theme.color_text_default_1} !important;
    text-align: justify !important;
  }
  padding: 1rem !important;
  padding-bottom: ${({ theme }) => theme.spacing_05}!important;
  a:focus {
    background: none !important;
    box-shadow: none !important;
  }
  &.mobileFound {
    padding: 1rem !important;
  }
  &.notMobile {
    padding: 1.5rem !important;
  }
`;
