import React, { ReactElement } from 'react';

import { GridItem, Heading, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { RegistrationContent, SetupPasswordProps } from './Registration.config';
import SetPassword from '../../components/setPassword/setPassword';

export default function SetupPassword({
  handleStepper,
  handleOnPassword,
  mtaCustomerId,
}: SetupPasswordProps): ReactElement {
  const { createYourAccountLabel, createPasswordSubText } =
    useContent<RegistrationContent>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    <GridItem>
      <>
        <Heading size="s4" marginBottom="02" aria-level={3}>
          {createYourAccountLabel}
        </Heading>
        <Text size="s2">{createPasswordSubText}</Text>
      </>
      <SetPassword
        isForgotPassword={false}
        isRegistration
        isLogin={false}
        handleOnClick={handleStepper}
        handleOnPasswordValue={handleOnPassword}
        oldPasswordErrorMessage=""
        mtaCustomerId={mtaCustomerId}
      />
    </GridItem>
  );
}
