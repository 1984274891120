import axios from 'axios';

import { ApiEndpoints } from '../constants/apiEndpoints';
import { isInt } from '../utils/handleEnvVariables';

const config = {
  headers: {
    'content-Type': 'application/json',
    'x-lbg-user-id': '12',
    'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
  },
};

export const forgotEmailAPI = (dob, postcode): Promise<object> =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `${window.appConfig.REACT_APP_API_URL}${window.appConfig.REACT_APP_UNAUTH_PROXY}/mta/api/v1/${ApiEndpoints.FORGOT_EMAIL}/${dob}/${postcode}`,
        {
          headers: {
            ...config.headers,
            'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
            ...(isInt ? { 'x-lbg-env': 'int1' } : {}),
          },
        },
      )
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
