import { IApiResponseProps } from '../../routes/multiAgreements/multiAgreement.config';
import { MultiAgreementInterface } from '../action/multiAgreement.action';

export interface IMultipleAgreementState {
  customerData: IApiResponseProps[];
}
const responseData = {
  contractId: '',
  vehicleMakeModel: '',
  vehicleReg: '',
  colour: '',
  redirectLink: '',
  isArchived: false,
  customerId: '',
  yearOfManufacture: null,
  monthOfFirstRegistration: '',
  dvlaApiMake: '',
};
const initialState = {
  customerData: [responseData],
};

export const MultiAgreementReducer = (
  state: IMultipleAgreementState = initialState,
  action: MultiAgreementInterface = {
    type: '',
    ...initialState,
  },
): IMultipleAgreementState => {
  if (action.type === 'CUSTOMER_DATA') {
    return {
      ...state,
      customerData: action.customerData,
    };
  }
  return state;
};
