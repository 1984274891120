import React, { ReactElement, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { RegistrationMainProps } from './Registration.config';
import RegistrationMTA from './RegistrationMTA';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';

export default function Registration({
  brand,
  setPasswordValue,
}: RegistrationMainProps) {
  const dispatch = useDispatch();
  const { customerData } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const handleOnModalOpen = (): void => {
    dispatch(DataModelAction(true));
  };
  const [isRegistered, setIsRegistered] = useState(
    customerData[0].isRegistered,
  );
  const numberOfSteps = 3;

  const handleIsRegistered = (value: boolean): void => setIsRegistered(value);

  const renderComponent = (): ReactElement => (
    <RegistrationMTA
      brand={brand}
      numberOfSteps={numberOfSteps}
      setPasswordValue={setPasswordValue}
      handleOnModalOpen={handleOnModalOpen}
      isRegistered={isRegistered}
      handleIsRegistered={handleIsRegistered}
    />
  );

  return renderComponent();
}
