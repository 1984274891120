export interface ILoginInfo {
  type: string;
  emailAddress: string;
}

export const forgotEmailAction = (emailAddress: string): ILoginInfo => ({
  type: 'FORGOT_EMAIL',
  emailAddress,
});

export interface ILoginModalInfo {
  type: string;
  loginConstraints: {
    isLoginAttemptPrevented: boolean;
    isLoginAttemptExceeded: boolean;
    isRegistered: boolean;
  };
}

export const loginModalAction = (loginConstraints: {
  isLoginAttemptPrevented: boolean;
  isLoginAttemptExceeded: boolean;
  isRegistered: boolean;
}): ILoginModalInfo => ({
  type: 'LOGIN_MODAL_CONSTRAINTS',
  loginConstraints,
});
export interface ILoginCredentials {
  type: string;
  isLoginCredentials: boolean;
}

export interface IOldPasswordProps {
  type: string;
  oldPassword: string;
}

export const LoginCredentialsAction = (
  isLoginCredentials: boolean,
): ILoginCredentials => ({
  type: 'LOGIN_CREDENTIALS',
  isLoginCredentials,
});

export const OldPasswordValueSet = (
  oldPassword: string,
): IOldPasswordProps => ({
  type: 'OLD_PASSWORD',
  oldPassword,
});
