import React, { ReactElement } from 'react';

import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  RegistrationContent,
  RegistrationModalParams,
  RegistrationModalProps,
} from './Registration.config';
import InfoIcon from '../../assets/icons/infoIcon';
import WarningIcon from '../../assets/icons/warningIcon';
import DataModal from '../../components/dataModal/dataModal';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { isEmptyString } from '../../utils/isEmptyString';
import * as routes from '../manifest';

export default function RegistrationModal({
  handleCloseWarningDialog,
  handleOnNavigateHomepage,
  isAgreementFound,
  isContactFound,
  isCustomerRecordFound,
  isRegistered,
  brand,
  isApiFailed,
  isLogin,
  handleBackToResendPasscode,
}: RegistrationModalProps): ReactElement {
  const {
    noContactFoundHeading,
    noContactFoundDescription,
    backToHomeButton,
    contactUsLink,
    noCustomerRecordFoundDescription,
    noCustomerRecordFoundHeading,
    alreadyRegisteredLabel,
    alreadyRegisteredDescription,
    needHelpText,
    registrationLabel,
    dataRetrievalErrorHeading,
    dataRetrievalErrorDescription,
    tryAgainLink,
    phoneNumber,
    constrainErrorMessage,
  } = useContent<RegistrationContent>();
  const theme: any = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataModelDetails = useSelector(
    (state: RootState) => state.DataModalReducer,
  );
  const { passwordFailureOrigin } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const handleOnNavigateHelpCentre = (): void => {
    navigate(routes.HelpCentre);
  };
  const handleContactUsLink = (): void => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const handleTryAgainButton = (): void => {
    if (isLogin) handleOnNavigateHomepage();
    dispatch(DataModelAction(false));
  };
  const NoContactFoundModalParams: RegistrationModalParams = {
    heading: noContactFoundHeading,
    description: noContactFoundDescription,
    dataQaId: {
      btn: dataQaIds.loginAndRegistration.noContactFoundModalBtn,
      link: dataQaIds.loginAndRegistration.noContactFoundModalLink,
    },
    buttonText: backToHomeButton,
    icon: <WarningIcon colour="white" />,
    onClickLink: handleCloseWarningDialog,
    onClick: handleOnNavigateHomepage,
    popupName: 'Missing information',
  };
  const NoCustomerRecordFoundModalParams: RegistrationModalParams = {
    heading: noCustomerRecordFoundHeading,
    description: noCustomerRecordFoundDescription,
    dataQaId: {
      btn: dataQaIds.loginAndRegistration.noCustomerRecordFoundModalBtn,
      link: dataQaIds.loginAndRegistration.noCustomerRecordFoundModalLink,
    },
    buttonText: backToHomeButton,
    icon: <WarningIcon colour="white" />,
    onClickLink: handleContactUsLink,
    onClick: handleOnNavigateHomepage,
    popupName: 'Customer Record Not Found',
  };
  const CustomerAlreadyRegisterdModalParams: RegistrationModalParams = {
    heading: alreadyRegisteredLabel,
    description: alreadyRegisteredDescription,
    dataQaId: {
      btn: dataQaIds.loginAndRegistration.customerAlreadyRegisteredModalBtn,
      link: dataQaIds.loginAndRegistration.customerAlreadyRegisteredModalLink,
    },
    buttonText: registrationLabel,
    icon: <InfoIcon colour={theme.color_text_default_1} />,
    onClickLink: handleOnNavigateHelpCentre,
    onClick: handleOnNavigateHomepage,
    popupName: 'Already Registered',
  };
  const ApiFailureModalParams: RegistrationModalParams = {
    heading: dataRetrievalErrorHeading,
    description: dataRetrievalErrorDescription,
    dataQaId: {
      btn: dataQaIds.loginAndRegistration.dataRetrievalModalBtn,
      link: dataQaIds.loginAndRegistration.dataRetrievalModalLink,
    },
    buttonText: tryAgainLink,
    icon: <WarningIcon colour={theme.color_text_default_1} />,
    onClickLink: handleContactUsLink,
    onClick: handleTryAgainButton,
    popupName: 'Data Retrieval Error',
  };
  const PasswordFailureParams: RegistrationModalParams = {
    heading: '',
    description: constrainErrorMessage,
    dataQaId: {
      btn: dataQaIds.loginAndRegistration.dataRetrievalModalBtn,
      link: dataQaIds.loginAndRegistration.dataRetrievalModalLink,
    },
    buttonText: 'Back to resend passcode',
    icon: <WarningIcon colour={theme.color_text_default_1} />,
    onClick: handleBackToResendPasscode,
    popupName: 'Data Retrieval Error',
  };

  const handlePhoneLink = (): boolean => {
    return !(
      !isAgreementFound ||
      !isCustomerRecordFound ||
      isApiFailed ||
      isRegistered ||
      !isEmptyString(passwordFailureOrigin)
    );
  };
  const handleLink = (): string => {
    if (
      (!isAgreementFound || !isCustomerRecordFound || isApiFailed) &&
      isEmptyString(passwordFailureOrigin)
    )
      return contactUsLink;
    if (isRegistered && isEmptyString(passwordFailureOrigin))
      return needHelpText;
    return '';
  };
  const renderModal = (params: RegistrationModalParams): ReactElement => (
    <DataModal
      icon={params.icon}
      heading={params.heading}
      description={params.description}
      buttonTxt={params.buttonText}
      onClick={params.onClick}
      link={handleLink()}
      closeIcon={false}
      isPhoneLink={handlePhoneLink()}
      onClose={handleCloseWarningDialog}
      onClickLink={params.onClickLink}
      dataQaId={{
        btn: params.dataQaId.btn,
        link: params.dataQaId.link,
      }}
      brand={brand}
      popupName={params.popupName}
    />
  );

  switch (true) {
    case !isEmptyString(passwordFailureOrigin):
      return renderModal(PasswordFailureParams);
    case isApiFailed && isEmptyString(passwordFailureOrigin):
      return renderModal(ApiFailureModalParams);
    case !isAgreementFound || !isCustomerRecordFound:
      return renderModal(NoCustomerRecordFoundModalParams);
    case isRegistered && !isApiFailed:
      return renderModal(CustomerAlreadyRegisterdModalParams);
    case !isContactFound && !isApiFailed && dataModelDetails.openDialog:
      return renderModal(NoContactFoundModalParams);
    default:
      return <span />;
  }
}
