import common from './common';
import registration from './registration';
import { AppContent } from '../AppContent';

const welcome: AppContent['welcome'] = {
  welcomeMessage: 'Finance to suit you',
  goBackLabel: 'Go back',
  logIntoAccountLabel: 'Log in to your account',
  loginNotRegisteredModalSubText:
    'In order to log in, we need to verify your details.',
  loginRegisterModalButtonText: 'Continue to registration',
  loginFailedDataModalHeading: 'We can’t log you in',
  loginAttemptsExceededModalSubText:
    'You’ve used the wrong details too many times. Please reset your password.',
  loginAttemptPreventedModalSubText:
    'Please contact us, so we can try to resolve this.',
  loginResetPasswordModalButtonText: 'Reset password',
  loginNotRegisteredApiErrorMessage: 'Not Registered',
  loginAccountNotActiveErrorMessage: 'Account not Active',
  loginWssUpdatePasswordErrorMessage: 'Update password.',
  ...common,
  ...registration,
};

export default welcome;
