import common from './common';
import { AppContent } from '../AppContent';

const helpCentre: AppContent['helpCentre'] = {
  mainHeading: `Creating an account and logging in.`,
  headingLogin: 'Log in',
  headingReg: 'Creating an online account',
  headingMoreHelp: 'More help and support',
  moreHelpContent: `Find our contact information, Money worries support, and details about the complaints process.`,
  headingTalkToUs: 'Talk to us',
  talkToUsNum: '03448 248 888',
  talkToUsTime: `Our lines are open Monday to Friday 8.30am - 6.00pm and Saturday 9.00am - 1.00pm.`,
  talkToUsSupport: `If you need to use a textphone, you can call us using the Text Relay Service. Please dial 1800 followed by the phone number above.`,
  talkToUsContent: `Calls will cost no more than a UK national rate call to 01 or 02 telephone numbers and will count towards inclusive UK call minutes provided in telephone and mobile contracts. Calls may be recorded or monitored to help us improve our service.`,
  headingWriteTous: `Write to us`,
  writeToBrand: `Black Horse`,
  writeToStreet: `St William House`,
  writeToCity: `Cardiff`,
  writeToCode: `CF10 5BH`,
  writeToUsContent: `Please include a telephone number so we can call you if we need to.`,
  goBack: `Go Back`,
  helpCentreLoginQns: [
    {
      hcQuestions: `What do I do if I've forgotten the email address I need to log in?`,
      hcQnsAnswer: `Please use the email address you used when you created your online account with us. We sent a confirmation email to this address when you created your online account. If you can't remember which email address you used to register, follow the “Forgotten your email address?” link from the log in page. Follow the instructions to retrieve the email address you used to register.`,
    },
    {
      hcQuestions: `What do I do if I've forgotten my password?`,
      hcQnsAnswer: `If you've forgotten your password, follow the “Forgotten your password?” link from the log in screen, and follow the instructions.<br/><br/>As a reminder, when creating your password, we asked you to create a password that included: between 8-32 characters; at least one uppercase letter; at least one lowercase letter; at least one number, and at least one special character.`,
    },
    {
      hcQuestions: `Why can't I log in?`,
      hcQnsAnswer: `Please check that you're using the correct email address and password.<br/><br/>It might be that you've tried to log in with incorrect details too many times. To protect your account, you'll need to reset your password before you can log in.<br/><br/>It's been more than 6 years since your agreement ended. Please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm) for further help.<br/><br/>We may not be able to provide an online service for your agreement. Please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm) for further help.`,
    },
    {
      hcQuestions: `I'm worried someone else might be logging into my account. What should I do?`,
      hcQnsAnswer: `If you think that someone else might be accessing your online account, you should immediately change your password. If you're still concerned, please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm).`,
    },
    {
      hcQuestions: 'My online account has been blocked. What should I do?',
      hcQnsAnswer: `You may have tried to log in with incorrect details too many times. To protect your account, you'll need to reset your password by selecting the “Forgotten your password?” link from the log in screen. If you still need help, please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm).`,
    },
    {
      hcQuestions: `I've requested a validation passcode by text message, but it hasn't arrived.`,
      hcQnsAnswer: `If this happens, request to resend the code. Check your phone has enough signal to receive the text.<br/><br/>If that doesn't work, the mobile number linked to your account might be wrong. Please call us for login support on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm).`,
    },
    {
      hcQuestions: `Why haven't you been able to verify my identity?`,
      hcQnsAnswer: `There are a few potential reasons for this:<br/><br/>There isn't a mobile number registered to your agreement, or the number we have is wrong. If you think this might be the case, please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm).<br/><br/>The passcode may have expired. Your validation passcode by text message is only valid for 5 minutes. Please request a new passcode.<br/><br/>You may have entered the passcode incorrectly, in which case you'll need to request a new passcode.`,
    },
    {
      hcQuestions: 'What will you use my email address for?',
      hcQnsAnswer: `Your email address is used to:<br/><br/>Log in to your online account.<br/><br/>Get emails when you make changes on your account, such as making a payment, or updating your details.<br/><br/>Get alerts when there are new letters and statements to view in your inbox. This applies if you have chosen to go paperless.`,
    },
  ],
  helpCentreRegistrationQns: [
    {
      hcQuestions: `Why create an online account?`,
      hcQnsAnswer: `<ul><li>You'll have 24/7 access to your agreement information.</li><li>You can make a payment or get a settlement quote online.</li><li>You may be able to change your payment date.</li><li>You can view your past and future payments and other transactions.</li><li>You can keep your account details updated.</li></ul>Creating an online account only takes a few minutes. All we need is your date of birth, postcode, as well as an email address and valid UK mobile number.`,
    },
    {
      hcQuestions: `Why can't we find your agreement?`,
      hcQnsAnswer: `There may be a few possible reasons:<br/><br/>You may have just entered the wrong details. Please check your details and try again.<br/><br/>If you've changed address since signing the agreement and not told us, then please use your previous postcode to create your online account. Please then update your address from your profile page. Please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm) if you need any help.<br/><br/> If your agreement is registered in the name of a business, please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm) for help with this type of agreement.<br/><br/>You can’t use this service if your agreement ended more than 6 years ago.<br/><br/>For further help, please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm).`,
    },
    {
      hcQuestions: `Why do I need to confirm my identity?`,
      hcQnsAnswer: `We need to confirm who you are, to prevent someone unauthorised from accessing your account and to keep your information safe.`,
    },
    {
      hcQuestions: `I'm having trouble confirming my identity by text message.`,
      hcQnsAnswer: `This could be down to a few reasons:<br/><br/>The mobile number we have for you is wrong. If you think this might be the case, please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm) for help.<br/><br/>You may have entered the passcode incorrectly.<br/><br/>Your passcode might have expired. The code we send you expires after 5 minutes. Select the resend code link to try again.<br/><br/>If you're still having trouble confirming your identity, please call us on <a href="tel:03448 248 888">03448 248 888</a> (Mon-Fri: 8.30am - 6pm, Sat: 9am - 1pm).`,
    },
    {
      hcQuestions: `How do I choose a password?`,
      hcQnsAnswer:
        'Create a password that is safe and secure, and not easy for anyone else to remember. To help with this, we have set some rules that your password should follow. Your password must:<ul><li>be between 8-32 characters;</li><li>include at least one uppercase letter;</li><li>include at least one lowercase letter;</li><li>include at least one number.</li><li>include at least one of these special characters: [ ! " # $ % & &#8242; ( ) * + , - . / : ; < = > ? @ &#8726; ^ _ ` { | } ~ £ € ¬ ]</li></ul',
    },
  ],
  headingText: `Help Centre`,
  ...common,
};

export default helpCentre;
