import { ContentGroup, Dialog, Grid } from '@constellation/core';
import styled from 'styled-components';

import { DataModalProps } from './DataModel.config';
import { device } from '../../utils/breakpoints';

export const StyledDialog = styled(Dialog)<Partial<DataModalProps>>`
  border-radius: ${({ theme }) => theme.spacing_02};
`;
export const StyledDataModalLink = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing_05};
  margin-bottom: ${({ theme }) => theme.spacing_03};
  @media ${device.md} {
    margin-bottom: 0;
  }
`;

export const StyledDialogButtonGrid = styled(Grid)<Partial<DataModalProps>>`
  margin-top: ${({ theme }) => theme.spacing_07};
  ${({ isLink, theme }) =>
    isLink ? `margin-bottom: ${theme.spacing_03}` : 'margin-bottom: 0'};
  @media ${device.md} {
    margin-bottom: 0;
  }
`;

export const StyledPhoneLinkContainer = styled(ContentGroup)`
  margin-bottom: ${({ theme }) => theme.spacing_03} !important;
  @media ${device.md} {
    margin-bottom: 0 !important;
  }
`;
