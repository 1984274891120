import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { Button, Grid, GridItem, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';

import { ForgotPasswordContent } from './forgotPassword.config';
import LoginAuthenticate from './loginAuthenticate';
import SetupPassword from './setupPassword';
import VerifyEmailAddress from './verifyEmailAddress';
import { authSMSSender } from '../../apis/authSMSSenderApi';
import { forgotPasswordApi } from '../../apis/forgotPasswordApi';
import { StyledBox } from '../../components/appConfig/common.styled';
import FormStepper from '../../components/formStepper/FormStepper';
import NavigatePreviousLink from '../../components/navigatePreviousLink';
import NeedHelp from '../../components/needHelp/NeedHelp';
import OTPAuthentication from '../../components/otpAuthentication/otpAuthentication';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  changeStepAction,
  changeNumberOfStepsAction,
} from '../../store/action/formStep.action';
import { forgotEmailAction } from '../../store/action/login.action';
import {
  DOBPostcodeContractsDataAction,
  PasswordFailureAction,
} from '../../store/action/registration.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dataLayer } from '../../utils/dataLayer';
import CustomerIdentification from '../forgotEmail/customerIdentification';
import * as routes from '../manifest';
import { ApiAuthResponse } from '../registration/Registration.config';
import RegistrationModal from '../registration/registrationModal';

export default function ForgotPassword({
  brand,
  contactNumber,
  mtaCustomerId,
  isContactFound,
  numberOfSteps,
  isAuthenticated,
  authLiteUUID,
  handlePassDetails,
  handleAuthenticated,
  handleIsContactFound,
  authToken,
  setAuthToken,
  wssCustomerID,
  isRegistered,
  handleOnIsRegistered,
}): ReactElement {
  const navigate = useNavigate();
  const isDeviceFound = useDeviceResizing();
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [journeySessionId, setJourneySessionId] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [isEmailError, setIsEmailError] = useState(true);
  const [isCustomerIdentificationError, setIsCustomerIdentificationError] =
    useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const isApiFailed = useRef(false);
  const dispatch = useDispatch();
  const utilsStore = useSelector((state: RootState) => state.UtilsReducer);
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const { emailAddress } = useSelector(
    (state: RootState) => state.LoginReducer,
  );
  const [email, setEmail] = useState(emailAddress);
  const {
    apiErrorMessage,
    authenticateHeading,
    resetPasswordTitle,
    continueLabel,
    sendPasscodeLabel,
    forgottenPasswordLink,
    needHelpLoggingIn,
  } = useContent<ForgotPasswordContent>();

  useEffect(() => {
    dispatch(ShowHideSpinnerAction(false));
    dispatch(DataModelAction(false));
    dispatch(changeNumberOfStepsAction(numberOfSteps));
  }, [dispatch, numberOfSteps]);
  const journeyStepName = (): string => {
    if (!isAuthenticated || isOTP) {
      return 'Verify Email Address';
    }
    if (isCustomerIdentificationError) {
      return 'Customer Not Found';
    }
    if (!isContactFound) {
      return 'Mobile number not found';
    }
    return 'Send passcode';
  };
  useEffect(() => {
    const stepName = (): string => {
      if (stepNumber === 1) {
        return isOTP ? 'Verify otp' : journeyStepName();
      }
      return 'Reset Password';
    };
    window.appConfig.JOURNEY_NAME = 'Forgot Password';
    window.appConfig.JOURNEY_STEP_NAME = stepName();
    window.appConfig.PAGE_ROLE = 'Servicing';
    dataLayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stepNumber,
    isOTP,
    isAuthenticated,
    isContactFound,
    isCustomerIdentificationError,
  ]);

  const handleOnStep = (): void => {
    dispatch(
      changeStepAction(
        stepNumber < numberOfSteps ? stepNumber + 1 : numberOfSteps,
      ),
    );
  };

  const renderOTPComponent = (): void => setIsOTP(true);

  const handleSetEmailAddress = (value: string): void => {
    setEmail(value);
    dispatch(forgotEmailAction(value));
  };

  const handleEmailError = (value: boolean): void => setIsEmailError(value);

  const handleAuthToken = (value: string): void => setAuthToken(value);

  const handleCloseWarningDialog = (): void => {
    dispatch(DataModelAction(false));
    setIsContinueClicked(false);
  };
  const handleOnClickContinue = (): void => {
    setIsContinueClicked(true);
    if (!isEmailError && !isAuthenticated && !isOTP && isRegistered) {
      setIsApiCalled(true);
      setIsContinueClicked(false);
      dispatch(ShowHideSpinnerAction(true));
      forgotPasswordApi(email)
        .then((res: { data: { Data: ApiAuthResponse } }) => {
          handlePassDetails([res.data.Data]);
          setIsApiCalled(false);
          isApiFailed.current = false;
          dispatch(ShowHideSpinnerAction(false));
        })
        .catch((err) => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          if (err.message === apiErrorMessage) {
            setIsCustomerIdentificationError(true);
            handleAuthenticated(true);
          } else {
            isApiFailed.current = true;
            dispatch(DataModelAction(true));
          }
        });
    } else if (!isRegistered) {
      handleOnIsRegistered(true);
      handleAuthenticated(false);
    } else if (
      stepNumber === 1 &&
      isContactFound &&
      isRegistered &&
      isAuthenticated
    ) {
      setIsApiCalled(true);
      dispatch(ShowHideSpinnerAction(true));
      authSMSSender(mtaCustomerId, wssCustomerID)
        .then((res: any) => {
          dispatch(ShowHideSpinnerAction(false));
          setJourneySessionId(res.authSessionId);
          setExpiryTime(res.otpExpiresAt);
          renderOTPComponent();
          setIsContinueClicked(false);
          setIsApiCalled(false);
        })
        .catch(() => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          setIsContinueClicked(false);
        });
    }
  };
  const renderStepTitle = (): string => {
    if (stepNumber === 1) {
      return authenticateHeading;
    }
    return resetPasswordTitle;
  };
  const renderContinueButtonLabel = (): string => {
    if (stepNumber === 1 && !isContactFound) {
      return continueLabel;
    }
    if (stepNumber === 1 && !isRegistered) {
      return continueLabel;
    }
    if (!isAuthenticated) {
      return continueLabel;
    }
    return sendPasscodeLabel;
  };
  const handleOnSessionId = (value: string): void => {
    setJourneySessionId(value);
  };
  const handleBackToResendPasscode = (): void => {
    setIsOTP(false);
    dispatch(changeStepAction(1));
    dispatch(PasswordFailureAction(''));
    dispatch(DataModelAction(false));
  };

  const handleOnButtonRenderCondition = (): boolean => {
    if (isOTP) return false;
    if (
      stepNumber === 1 &&
      isAuthenticated &&
      !isContactFound &&
      isRegistered
    ) {
      return false;
    }
    if (
      stepNumber === 1 &&
      !isOTP &&
      isAuthenticated &&
      isContactFound &&
      !isCustomerIdentificationError
    ) {
      return true;
    }
    if (stepNumber === 1 && isAuthenticated && !isRegistered) return true;
    if (stepNumber === 1 && !isAuthenticated) return true;
    if (
      stepNumber === 1 &&
      !isOTP &&
      !isCustomerIdentificationError &&
      !isContactFound
    ) {
      return true;
    }
    return stepNumber === 2 && isOTP;
  };
  const renderButton = (): ReactElement =>
    handleOnButtonRenderCondition() && (
      <Grid alignX="right">
        <Button onClick={handleOnClickContinue}>
          {renderContinueButtonLabel()}
        </Button>
      </Grid>
    );
  const renderStepContent = (): ReactElement => {
    if (stepNumber === 1 && isAuthenticated && !isOTP && isRegistered) {
      return (
        <LoginAuthenticate
          isCustomerIdentificationError={isCustomerIdentificationError}
          isNumberFound={isContactFound}
          phoneNumber={contactNumber}
          isLogin={false}
        />
      );
    }
    if (isAuthenticated && !isOTP && !isRegistered)
      return <CustomerIdentification />;
    if (stepNumber === 1 && isOTP) {
      return (
        <OTPAuthentication
          brand={brand}
          handleSendOtp={handleOnClickContinue}
          phoneNumber={contactNumber}
          isMobileFound={isDeviceFound.mobile}
          sessionId={journeySessionId}
          isForgetPassword
          handleStepper={handleOnStep}
          mtaCustomerId={mtaCustomerId}
          handleSessionId={handleOnSessionId}
          handleAuthToken={handleAuthToken}
          expiryTime={expiryTime}
          isLogin={false}
          isForgotEmail={false}
        />
      );
    }
    if (stepNumber === 2) {
      return (
        <SetupPassword
          isForgotPassword
          handleApiCalled={setIsApiCalled}
          handleStepper={handleOnStep}
          mtaCustomerId={mtaCustomerId}
          userId={authLiteUUID}
          wssCustomerId={wssCustomerID}
          brand={brand}
          authToken={authToken}
          emailAddress={email || emailAddress}
          handleBackToResendPasscode={handleBackToResendPasscode}
          oldPassword=""
        />
      );
    }
    return (
      <VerifyEmailAddress
        handleEmailAddress={handleSetEmailAddress}
        isContinueClicked={isContinueClicked}
        handleEmailError={handleEmailError}
        email={email || emailAddress}
      />
    );
  };
  const handlePreviousNavigate = (): void => {
    if (stepNumber === 2) {
      dispatch(changeStepAction(stepNumber - 1));
      setIsOTP(true);
    } else if (isOTP) {
      setIsOTP(false);
    } else if (isAuthenticated) {
      handleAuthenticated(false);
      handleIsContactFound(true);
      setIsCustomerIdentificationError(false);
      handleOnIsRegistered(true);
      setIsContinueClicked(false);
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: false,
        }),
      );
    } else if (stepNumber === 1) {
      navigate(routes.WelcomePage);
    } else {
      dispatch(changeStepAction(stepNumber - 1));
    }
  };
  const handleOnRender = (): ReactElement =>
    isApiCalled && utilsStore.showSpinner ? (
      <SpinnerContainer brand={brand} />
    ) : (
      <Grid>
        <GridItem lg={2} />
        <GridItem lg={8}>
          <NavigatePreviousLink
            navigateRoute={handlePreviousNavigate}
            headingText={forgottenPasswordLink}
            dataQaId={dataQaIds.loginAndRegistration.previousLink}
          />
          <Heading size="s7" marginBottom="03" aria-level={2}>
            {renderStepTitle()}
          </Heading>
          <FormStepper activeStep={stepNumber} numberOfSteps={numberOfSteps} />
          <StyledBox padding>
            <Grid>{renderStepContent()}</Grid>
            <GridItem>{renderButton()}</GridItem>
          </StyledBox>
          <NeedHelp
            hasHeading
            customSubtitle={needHelpLoggingIn}
            dataQaId={dataQaIds.needhelp.regContainer}
          />
        </GridItem>
        <GridItem lg={2} />
        <RegistrationModal
          brand={brand}
          isAgreementFound
          isContactFound
          isCustomerRecordFound
          handleCloseWarningDialog={handleCloseWarningDialog}
          handleBackToResendPasscode={handleBackToResendPasscode}
          isRegistered={false}
          isApiFailed={isApiFailed.current}
          isLogin={false}
        />
      </Grid>
    );
  return handleOnRender();
}
