import { Box, Text } from '@constellation/core';
import BoxProps from '@constellation/core/dist/types/components/Box/box.types';
import { ChevronRight } from '@constellation/core/icons';
import styled from 'styled-components';

import { IArchivedState } from './multiAgreement.config';
import { device } from '../../utils/breakpoints';

export const StyledChevronIcon = styled(ChevronRight)`
  padding: ${({ theme }) => theme.spacing_01} !important;
  width: ${({ theme }) => theme.spacing_04} !important;
  height: ${({ theme }) => theme.spacing_04} !important;
`;

export const StyledCarImage = styled('img')<IArchivedState>`
  opacity: ${({ isArchived }) => (isArchived ? '0.4' : '1')} !important;
  width: 30%;
  margin-left: auto;
  margin-top: ${({ theme }) => theme.spacing_03};
  display: flex;
  margin-right: 10px;
  align-items: center;
  @media ${device.md} {
    margin-right: ${({ theme }) => theme.spacing_05} !important;
  }
  @media ${device.lg} {
    width: 20%;
  }
`;

export const StyledCarImageContainer = styled('div')<IArchivedState>`
  opacity: ${({ isArchived }) => (isArchived ? '0.4' : '1')} !important;
  width: 120px;
  margin-left: 10px;
  margin-top: ${({ theme }) => theme.spacing_03};
  display: flex;
  margin-right: 10px;
  align-items: center;
  @media ${device.md} {
    width: 140px;
    margin-right: ${({ theme }) => theme.spacing_05} !important;
  }
`;

export const StyledBox = styled(Box)<BoxProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  &:hover {
    ${({ theme }) =>
      `box-shadow:
  0px 0px 6px 0px ${theme.color_action_inverse_foreground_tertiary_disabled} inset;`}
  }
`;

export const StyledFlex = styled('div')`
  display: flex;
`;

export const StyledSuplimentaryText = styled(Text)<IArchivedState>`
  color: ${({ isArchived }) =>
    isArchived
      ? ({ theme }) => theme.color_source_green_100
      : 'default'} !important;
`;

export const ClickableArea = styled('a')`
  cursor: pointer;
`;

export const StyledTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  span: first-child {
    order: 2;
    margin-top: ${({ theme }) => theme.spacing_05};
  }

  @media ${device.md} {
    flex-direction: row;
    align-items: center;
    span: last-child {
      order: 2;
    }
  }
`;
