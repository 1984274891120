import { GridItem, Link, ContentGroup } from '@constellation/core';
import styled from 'styled-components';

import { IDeviceInfo } from '../../routes/setMarketingPreferences/marketingPreferences.config';

export const StyledLink = styled(Link)`
  span {
    font-weight: 600;
    font-size: ${({ theme }) => theme.typography_text_font_size_s1};
  }
`;

export const StyledFooterDiv = styled('div')`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing_05};
  a {
    margin-right: ${({ theme }) => theme.spacing_05};
  }
  a:last-child {
    margin-right: 0;
  }
`;

export const StyledTextGridItem = styled(GridItem)`
  margin-top: ${({ isMobileFound }: IDeviceInfo) =>
    isMobileFound
      ? ({ theme }) => theme.spacing_05
      : ({ theme }) => theme.spacing_0};
  padding-left: ${({ isMobileFound, isTabletFound }: IDeviceInfo) =>
    !isMobileFound && !isTabletFound
      ? ({ theme }) => theme.spacing_05
      : ({ theme }) => theme.spacing_03} !important;
`;

export const StyledLinkContanier = styled(ContentGroup)`
  a {
    margin-right: ${({ theme }) => theme.spacing_05};
  }
  a:last-child {
    margin-right: 0;
  }
`;
