import axios from 'axios';

import { authZMockApi } from './authZMockApi';
import { ApiEndpoints } from '../constants/apiEndpoints';
import { isBld, isLocal, isNftInt, isPre } from '../utils/handleEnvVariables';

const config = {
  headers: {
    'x-lbg-client-id':
      isBld || isLocal || isNftInt
        ? 'xxxx'
        : window.appConfig.REACT_APP_CLIENT_ID,
    'x-lbg-source-system': isBld || isLocal || isNftInt ? 'MOCK' : 'AUTH_LITE',
    'x-lbg-auth-type': 'Implicit',
    'x-lbg-tracking-id': 'f81d4fae-7dec-11d0-a765-00a0c91e6bf6',
    'Content-Type': 'application/json',
  },
};
export const actualAuthZAPI = (authToken, codeChallenge): Promise<object> =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${window.appConfig.REACT_APP_AUTH_Z}/${ApiEndpoints.AUTH_Z}`,
        {
          client_id: 'AL21582MyTransportAssistantPublicClient',
          response_type: 'code',
          scope: 'read',
          state: 'abc243',
          redirect_uri: window.appConfig.REACT_APP_AUTH_URL,
          code_challenge: codeChallenge,
          code_challenge_method: 'S256',
          auth_token: isBld || isLocal || isNftInt ? 'test' : authToken,
          token_type: 'JWS',
        },
        {
          headers: {
            ...config.headers,
            'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
          },
        },
      )
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });

export const authZAPI = (authToken, codeChallenge): Promise<object> => {
  if (isPre) {
    const token = JSON.parse(atob(authToken.split('.')[1]));
    return authZMockApi(token.sub.replace('+', '%2B'));
  }
  return actualAuthZAPI(authToken, codeChallenge);
};
