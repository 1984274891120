import forgotPassword from './forgotPassword';
import registration from './registration';
import welcome from './welcome';
import { AppContent } from '../AppContent';

const setupPassword: AppContent['setupPassword'] = {
  createNewPasswordMessage: 'Create a new password',
  newPasswordText: 'Your login was successful',
  existingPasswordLabel: 'Existing password',
  newPasswordLabel: 'New password',
  confirmPasswordLabel: 'Confirm password',
  incorrectPasswordErrorMessage: 'Incorrect password',
  enterPasscodeHeading: 'Enter passcode',
  newPasswordSubText:
    'You’ll need to create a new secure password which meets our latest requirements to continue.',
  ...registration,
  ...welcome,
  ...forgotPassword,
};

export default setupPassword;
