import React, { ReactElement } from 'react';

import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { WelcomeContent } from './WelcomePage.config';
import WarningIcon from '../../assets/icons/warningIcon';
import DataModal from '../../components/dataModal/dataModal';
import { DataModalProps } from '../../components/dataModal/DataModel.config';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { loginModalAction } from '../../store/action/login.action';
import * as routes from '../manifest';

export default function LoginModal(): ReactElement {
  const {
    loginFailedDataModalHeading,
    loginAttemptPreventedModalSubText,
    loginAttemptsExceededModalSubText,
    loginNotRegisteredModalSubText,
    loginRegisterModalButtonText,
    loginResetPasswordModalButtonText,
    contactUsLink,
    phoneNumber,
  } = useContent<WelcomeContent>();
  const theme: any = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMTA = true;
  const { loginConstraints } = useSelector(
    (state: RootState) => state.LoginReducer,
  );
  const handleDispatchData = () => {
    dispatch(
      loginModalAction({
        isRegistered: true,
        isLoginAttemptExceeded: false,
        isLoginAttemptPrevented: false,
      }),
    );
  };
  const handleDataModalClosing = (): void => {
    dispatch(DataModelAction(false));
    handleDispatchData();
  };
  const handleContactUsLink = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleOnNavigateRegistration = () => {
    dispatch(DataModelAction(false));
    handleDispatchData();
    navigate(routes.Registration);
  };
  const handleOnNavigateForgotPassword = () => {
    dispatch(DataModelAction(false));
    handleDispatchData();
    navigate(routes.ForgotPassword);
  };
  const renderIcon = (): ReactElement => {
    return (
      <WarningIcon
        width="72"
        height="72"
        colour={
          isMTA
            ? theme.color_icon_default_2
            : theme.color_icon_inverse_default_2
        }
      />
    );
  };
  const renderLoginDataModal = ({
    heading,
    description,
    isPhoneLink,
    onClick,
    onClickLink,
    link,
    buttonTxt,
    dataQaId,
    popupName,
  }: Partial<DataModalProps>): ReactElement => {
    return (
      <DataModal
        icon={renderIcon()}
        heading={heading}
        description={description}
        buttonTxt={buttonTxt}
        isPhoneLink={isPhoneLink}
        onClick={onClick}
        link={link}
        onClickLink={onClickLink}
        brand={undefined}
        closeIcon
        onClose={handleDataModalClosing}
        dataQaId={dataQaId}
        popupName={popupName}
      />
    );
  };
  return (
    <>
      {loginConstraints.isLoginAttemptPrevented &&
        renderLoginDataModal({
          onClick: undefined,
          heading: loginFailedDataModalHeading,
          description: loginAttemptPreventedModalSubText,
          buttonTxt: '',
          isPhoneLink: true,
          dataQaId: {
            link: dataQaIds.loginAndRegistration.phoneLink,
          },
          popupName: 'We can’t log you in',
        })}
      {loginConstraints.isLoginAttemptExceeded &&
        renderLoginDataModal({
          heading: loginFailedDataModalHeading,
          description: loginAttemptsExceededModalSubText,
          buttonTxt: loginResetPasswordModalButtonText,
          link: contactUsLink,
          onClick: handleOnNavigateForgotPassword,
          brand: undefined,
          onClickLink: handleContactUsLink,
          dataQaId: {
            btn: dataQaIds.loginAndRegistration.isLockoutModalBtn,
            link: dataQaIds.loginAndRegistration.isLockoutModalLink,
          },
          popupName: 'Login Attempts Exceeded',
        })}
      {!loginConstraints.isRegistered &&
        renderLoginDataModal({
          heading: loginFailedDataModalHeading,
          description: loginNotRegisteredModalSubText,
          buttonTxt: loginRegisterModalButtonText,
          onClick: handleOnNavigateRegistration,
          dataQaId: {
            btn: dataQaIds.loginAndRegistration.noRegistrationCompletedModalBtn,
          },
          popupName: 'Login Verify Details',
        })}
    </>
  );
}
