import React from 'react';

import { Page } from '@constellation/core';
import { useLocation } from '@interstellar/react-app-routing';

import { StyledContainer } from './Applayout.styled';
import { LayoutProps } from './Applayout.types';
import WelcomePage from '../../routes/welcomePage/WelcomePage';
import FooterComponent from '../footer/FooterComponent';
import HeaderComponent from '../header/HeaderComponent';

function AppLayout({ children }: LayoutProps): React.JSX.Element {
  const location = useLocation();
  return location.pathname === '/' ? (
    <WelcomePage />
  ) : (
    <Page>
      <HeaderComponent />
      <StyledContainer>{children}</StyledContainer>
      <FooterComponent />
    </Page>
  );
}

export default AppLayout;
