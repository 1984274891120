import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ForgotPasswordProps } from './forgotPassword.config';
import ForgotPasswordMTA from './forgotPasswordMTA';
import { RootState } from '../../store';
import {
  ContactNumberFoundAction,
  DOBPostcodeContractsDataAction,
} from '../../store/action/registration.action';
import { isEmptyString } from '../../utils/isEmptyString';
import { ApiAuthResponse } from '../registration/Registration.config';

export default function ForgotPassword({
  brand,
  isAuthenticated,
  setIsAuthenticated,
  authToken,
  setAuthToken,
}: ForgotPasswordProps) {
  const dispatch = useDispatch();
  const { customerData, isContactNumberFound } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );

  const [mtaCustomerId, setMtaCustomerId] = useState('');
  const [wssCustomerID, setwssCustomerID] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isContactFound, setIsContactFound] = useState(isContactNumberFound);
  const [isRegistered, setIsRegistered] = useState(
    customerData[0].isRegistered,
  );
  const numberOfSteps = 2;

  const [authLiteUUID, setAuthLiteUUID] = useState('');

  const handleObsucredNumber = (phoneNumber: string): void => {
    setContactNumber(
      '*'.repeat(Math.max(0, phoneNumber.length - 4)) + phoneNumber.slice(-4),
    );
  };
  const handleOnIsRegistered = (value: boolean) => setIsRegistered(value);
  const isNoContactFound = (number: string): boolean => {
    if (isEmptyString(number)) {
      setIsContactFound(false);
      setIsAuthenticated(true);
      dispatch(ContactNumberFoundAction(false));
      return true;
    }
    setIsContactFound(true);
    dispatch(ContactNumberFoundAction(true));
    setIsAuthenticated(true);
    handleObsucredNumber(number);
    return false;
  };
  const handleOnNoContactCondition = (number: string): void => {
    if (isNoContactFound(number || customerData[0].obscuredPhoneNumber)) {
      setIsContactFound(false);
    } else {
      handleObsucredNumber(number);
    }
  };
  const handleAuthenticated = (value: boolean): void => {
    setIsAuthenticated(value);
  };
  const handleOnModalConditions = (res: ApiAuthResponse[]): void => {
    dispatch(
      DOBPostcodeContractsDataAction(res, {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: false,
      }),
    );
    handleOnNoContactCondition(res[0].obscuredPhoneNumber);
    setMtaCustomerId(res[0].mtaCustomerId);
    setwssCustomerID(res[0].wssCustomerId);
    setIsRegistered(res[0].isRegistered);
    setAuthLiteUUID('');
  };
  const handleIsContactFound = (value: boolean): void =>
    setIsContactFound(value);
  return (
    <ForgotPasswordMTA
      brand={brand}
      contactNumber={contactNumber || customerData[0].obscuredPhoneNumber}
      mtaCustomerId={mtaCustomerId || customerData[0].mtaCustomerId}
      isContactFound={isContactFound}
      numberOfSteps={numberOfSteps}
      isAuthenticated={isAuthenticated}
      authLiteUUID={authLiteUUID}
      handlePassDetails={handleOnModalConditions}
      handleAuthenticated={handleAuthenticated}
      handleIsContactFound={handleIsContactFound}
      authToken={authToken}
      setAuthToken={setAuthToken}
      wssCustomerID={wssCustomerID || customerData[0].wssCustomerId}
      isRegistered={isRegistered}
      handleOnIsRegistered={handleOnIsRegistered}
    />
  );
}
