import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';

import {
  Button,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';

import CustomerIdentification from './customerIdentification';
import { ForgotEmailContent } from './forgotEmail.config';
import { authSMSSender } from '../../apis/authSMSSenderApi';
import { confirmAccountAPI } from '../../apis/confirmAccountApi';
import { forgotEmailAPI } from '../../apis/forgotEmailApi';
import { StyledBox } from '../../components/appConfig/common.styled';
import FormStepper from '../../components/formStepper/FormStepper';
import NavigatePreviousLink from '../../components/navigatePreviousLink';
import NeedHelp from '../../components/needHelp/NeedHelp';
import OTPAuthentication from '../../components/otpAuthentication/otpAuthentication';
import RegistrationAuthenticate from '../../components/registrationAuthenticate/registrationAuthenticate';
import RegistrationYourDetails from '../../components/registrationYourDetails/registrationYourDetails';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  changeStepAction,
  changeNumberOfStepsAction,
} from '../../store/action/formStep.action';
import {
  ContactNumberFoundAction,
  CustomerAgreementNumberAction,
  DOBPostcodeContractsDataAction,
  HasRegistrationNumberAction,
  UpdateDOBPostcodeAction,
} from '../../store/action/registration.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dataLayer } from '../../utils/dataLayer';
import { dateFormat } from '../../utils/dateFormat';
import LoginAuthenticate from '../forgotPassword/loginAuthenticate';
import * as routes from '../manifest';
import { DateObject } from '../registration/Registration.config';
import RegistrationModal from '../registration/registrationModal';

export default function ForgotEmail({
  phoneNumber,
  brand,
  mtaCustomerId,
  handlePassDetails,
  isContactFound,
  numberOfSteps,
  isCustomerIdentificationError,
  handleCustomerIdentityError,
  handleIsContactFound,
  wssCustomerID,
}): ReactElement {
  const {
    constraints,
    dob,
    postcode,
    agreementNumber,
    hasAllRegistrationNumbers,
  } = useSelector((state: RootState) => state.RegistrationReducer);
  const [date, setDate] = useState<DateObject>(
    dob || {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  );

  const [postCode, setPostCode] = useState(postcode);
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [isDateField, setIsDateField] = useState(false);
  const [isPostCodeError, setIsPostCodeError] = useState(true);
  const [isDateError, setIsDateError] = useState(true);
  const [isOTP, setIsOTP] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [journeySessionId, setJourneySessionId] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [isAgreementFound, setIsAgreementFound] = useState(false);
  const [isMoreThanOneCustomer, setIsMoreThanOneCustomer] = useState(
    constraints.isMoreThanOneUser,
  );
  const [agreementNo, setAgreementNo] = useState(agreementNumber);
  const isApiFailed = useRef(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDeviceFound = useDeviceResizing();
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const utilsStore = useSelector((state: RootState) => state.UtilsReducer);
  const {
    yourDetailsHeading,
    authenticateHeading,
    apiErrorMessage,
    continueLabel,
    sendPasscodeLabel,
    forgottenEmailLink,
    userNotRegisteredErrorMessage,
  } = useContent<ForgotEmailContent>();
  const handleOnDate = (value: any): void => setDate(value);

  const handleOnPostCodeError = (value: boolean): void =>
    setIsPostCodeError(value);
  const handleOnDateError = (value: boolean): void => setIsDateError(value);
  const handleOnPostCode = (value: string): void => setPostCode(value);
  const handleOnSessionId = (value: string): void => {
    setJourneySessionId(value);
  };
  useEffect(() => {
    dispatch(changeNumberOfStepsAction(numberOfSteps));
  }, [dispatch, numberOfSteps]);
  const notFound =
    isCustomerIdentificationError ||
    !constraints.isDetailsFound ||
    !isContactFound;
  useEffect(() => {
    const stepName = (): string => {
      if (stepNumber === 1) {
        return constraints.isMoreThanOneUser
          ? 'Agreement number'
          : 'Personal details';
      }
      return !isOTP ? 'Send otp' : 'Verify';
    };
    window.appConfig.JOURNEY_NAME = 'Forgot Email';
    window.appConfig.JOURNEY_STEP_NAME = notFound
      ? 'Customer not found'
      : stepName();
    window.appConfig.PAGE_ROLE = 'Servicing';
    dataLayer();
  }, [stepNumber, constraints.isMoreThanOneUser, isOTP, notFound]);

  const handleCloseWarningDialog = (): void => {
    dispatch(DataModelAction(false));
    setIsContinueClicked(false);
  };
  const handleOnNavigateHomepage = (): void => {
    navigate(routes.WelcomePage);
  };

  const hasMultipleContracts = (res: any) =>
    Object.keys(res.account).length === 0;
  const handleSingleAgreement = (res: any) => handlePassDetails(res.account);

  const handleApiResponse = (res: any) => {
    if (hasMultipleContracts(res)) {
      setIsMoreThanOneCustomer(true);
      dispatch(HasRegistrationNumberAction(res.hasAllRegistrationNumbers));
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: true,
        }),
      );
      return;
    }
    dispatch(
      DOBPostcodeContractsDataAction([res.account], {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: false,
      }),
    );
    handleSingleAgreement(res);
  };
  const handleRequestBody = () => {
    if (hasAllRegistrationNumbers)
      return { vehicleRegistrationNumber: agreementNo, contractId: '' };
    return { vehicleRegistrationNumber: '', contractId: agreementNo };
  };

  const handleOnPostcodeFormat = (): string =>
    `${postCode.split(' ').join('')}`;
  const renderOTPComponent = (): void => setIsOTP(true);

  const renderStepTitle = (): string => {
    if (stepNumber === 1) {
      return yourDetailsHeading;
    }
    return authenticateHeading;
  };
  const handleDetailsNotFound = (): boolean =>
    isCustomerIdentificationError || !constraints.isDetailsFound;
  const handleApiError = (error: any, isMultipleAccount: boolean) => {
    if (
      error.message === apiErrorMessage ||
      error.response?.data?.StatusMsg === userNotRegisteredErrorMessage
    ) {
      isApiFailed.current = false;
      handleCustomerIdentityError(true);
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: false,
          isApiFailed: false,
          isMoreThanOneUser: isMultipleAccount,
        }),
      );
    } else {
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: isMultipleAccount,
        }),
      );
      isApiFailed.current = true;
      dispatch(DataModelAction(true));
    }
  };

  const handleOnClickContinue = (): void => {
    setIsContinueClicked(true);
    setIsDateField(true);
    dispatch(UpdateDOBPostcodeAction(postCode, date, false));
    if (handleDetailsNotFound() && !isMoreThanOneCustomer) {
      handleCustomerIdentityError(false);
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: false,
        }),
      );
    } else if (handleDetailsNotFound() && isMoreThanOneCustomer) {
      handleCustomerIdentityError(false);
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: true,
        }),
      );
    } else if (
      !isDateError &&
      !isPostCodeError &&
      stepNumber === 1 &&
      !isMoreThanOneCustomer &&
      (!isCustomerIdentificationError || constraints.isDetailsFound)
    ) {
      dispatch(ShowHideSpinnerAction(true));
      setIsApiCalled(true);
      dispatch(CustomerAgreementNumberAction(''));
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: false,
        }),
      );
      forgotEmailAPI(dateFormat(date, 'yyyy-mm-dd'), handleOnPostcodeFormat())
        .then((res: any) => {
          isApiFailed.current = false;
          handleApiResponse(res.Data);
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          setIsContinueClicked(false);
          handleCustomerIdentityError(false);
        })
        .catch((err: any) => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          handleApiError(err, false);
        });
    } else if (
      stepNumber === 1 &&
      isMoreThanOneCustomer &&
      !handleDetailsNotFound()
    ) {
      setIsApiCalled(true);
      dispatch(ShowHideSpinnerAction(true));
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: false,
        }),
      );
      dispatch(CustomerAgreementNumberAction(agreementNo));
      confirmAccountAPI(
        handleRequestBody(),
        dateFormat(date, 'yyyy-mm-dd'),
        handleOnPostcodeFormat(),
      )
        .then((res: any) => {
          isApiFailed.current = false;
          dispatch(ShowHideSpinnerAction(false));
          if (!res.data.Data.isRegistered) {
            setIsAgreementFound(res.data.Data.isRegistered);
            dispatch(DataModelAction(true));
            return;
          }
          handlePassDetails(res.data.Data);
          dispatch(
            DOBPostcodeContractsDataAction([res.data.Data], {
              isDetailsFound: true,
              isApiFailed: false,
              isMoreThanOneUser: true,
            }),
          );
          setIsApiCalled(false);
          setIsContinueClicked(false);
          handleCustomerIdentityError(false);
        })
        .catch((err: any) => {
          handleApiError(err, true);
          setIsApiCalled(false);
          dispatch(ShowHideSpinnerAction(false));
        });
    } else if (stepNumber === 2) {
      dispatch(ShowHideSpinnerAction(true));
      setIsApiCalled(true);
      authSMSSender(mtaCustomerId, wssCustomerID)
        .then((res: { authSessionId: string; otpExpiresAt: string }) => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          setJourneySessionId(res.authSessionId);
          setExpiryTime(res.otpExpiresAt);
          renderOTPComponent();
        })
        .catch(() => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
        });
    }
  };
  const renderContinueButtonLabel = (): string => {
    if (stepNumber === 1) {
      return continueLabel;
    }
    return sendPasscodeLabel;
  };

  const handleStepper = useCallback(
    (isReverse: boolean) => {
      switch (true) {
        case !isContactFound:
          handleIsContactFound(true);
          handleCustomerIdentityError(false);
          dispatch(ContactNumberFoundAction(true));
          break;
        case (isCustomerIdentificationError || !constraints.isDetailsFound) &&
          stepNumber === 1:
          handleCustomerIdentityError(false);
          dispatch(
            DOBPostcodeContractsDataAction([], {
              isDetailsFound: true,
              isApiFailed: false,
              isMoreThanOneUser: false,
            }),
          );
          break;
        case isMoreThanOneCustomer &&
          stepNumber === 1 &&
          (!isCustomerIdentificationError || constraints.isDetailsFound):
          setIsMoreThanOneCustomer(false);
          break;
        case !isMoreThanOneCustomer && stepNumber === 1:
          navigate(routes.WelcomePage);
          break;
        case !isOTP && stepNumber === 2:
          dispatch(changeStepAction(stepNumber - 1));
          break;
        case isOTP && stepNumber === 2:
          setIsOTP(false);
          break;

        default:
          dispatch(changeStepAction(stepNumber - 1));
          if (isReverse) {
            setIsContinueClicked(false);
            setIsPostCodeError(false);
            setIsDateError(false);
          }
          break;
      }
    },
    [
      constraints.isDetailsFound,
      dispatch,
      handleCustomerIdentityError,
      handleIsContactFound,
      isContactFound,
      isCustomerIdentificationError,
      isMoreThanOneCustomer,
      isOTP,
      navigate,
      stepNumber,
    ],
  );

  const handleOnPreviousLink = (): void => handleStepper(true);
  const renderStepContent = (): ReactElement => {
    if (!isContactFound) {
      return (
        <LoginAuthenticate
          isCustomerIdentificationError={false}
          isNumberFound={isContactFound}
          phoneNumber={phoneNumber}
          isLogin={false}
        />
      );
    }
    if (isCustomerIdentificationError || !constraints.isDetailsFound) {
      return <CustomerIdentification />;
    }
    if (stepNumber === 2 && !isOTP) {
      return (
        <RegistrationAuthenticate
          phoneNumber={phoneNumber}
          isNumberFound={isContactFound}
        />
      );
    }
    if (stepNumber === 2 && isOTP) {
      return (
        <OTPAuthentication
          brand={brand}
          isForgotEmail
          isMobileFound={isDeviceFound.mobile}
          sessionId={journeySessionId}
          phoneNumber={phoneNumber}
          handleSendOtp={handleOnClickContinue}
          mtaCustomerId={mtaCustomerId}
          isForgetPassword={false}
          handleSessionId={handleOnSessionId}
          expiryTime={expiryTime}
          isLogin={false}
        />
      );
    }
    return (
      <RegistrationYourDetails
        isContinueClicked={isContinueClicked}
        handleOnPostCodeError={handleOnPostCodeError}
        handleOnDateError={handleOnDateError}
        handleOnDate={handleOnDate}
        handleOnPostCode={handleOnPostCode}
        setIsContinueClicked={setIsContinueClicked}
        setIsDateField={setIsContinueClicked}
        isDateField={isDateField}
        isMoreThanOneCustomer={isMoreThanOneCustomer}
        handleAgreementNumber={setAgreementNo}
      />
    );
  };
  const handleOnButtonRenderCondition = (): boolean => {
    if (stepNumber === 1 && isContactFound) {
      return true;
    }
    return stepNumber === 2 && isContactFound && !isOTP;
  };
  const renderButtonGroup = (): ReactElement => (
    <ContentGroup marginRight="03" marginLeft="03" marginBottom="none">
      <Grid alignX="right">
        {handleOnButtonRenderCondition() && (
          <Button
            data-qa-id={dataQaIds.loginAndRegistration.confirmAndContinueBtn}
            data-testid="continueBtn"
            onClick={handleOnClickContinue}
          >
            {renderContinueButtonLabel()}
          </Button>
        )}
      </Grid>
    </ContentGroup>
  );
  const handleOnRender = (): ReactElement =>
    isApiCalled && utilsStore.showSpinner ? (
      <SpinnerContainer brand={brand} />
    ) : (
      <Grid>
        <GridItem lg={2} />
        <GridItem lg={8}>
          <NavigatePreviousLink
            headingText=""
            navigateRoute={handleOnPreviousLink}
          />
          <Text size="s2" weight="bold">
            {forgottenEmailLink}
          </Text>
          <Heading size="s7" marginBottom="03" aria-level={2} marginTop="05">
            {renderStepTitle()}
          </Heading>
          <FormStepper activeStep={stepNumber} numberOfSteps={numberOfSteps} />
          <StyledBox padding={stepNumber !== 1}>
            <Grid>
              {renderStepContent()}
              <GridItem>{renderButtonGroup()}</GridItem>
            </Grid>
          </StyledBox>
          <NeedHelp hasHeading dataQaId={dataQaIds.needhelp.regContainer} />
        </GridItem>
        <GridItem lg={2} />
        <RegistrationModal
          brand={brand}
          isAgreementFound={isAgreementFound}
          isContactFound
          isCustomerRecordFound
          handleCloseWarningDialog={handleCloseWarningDialog}
          handleOnNavigateHomepage={handleOnNavigateHomepage}
          isApiFailed={isApiFailed.current}
          isLogin={false}
        />
      </Grid>
    );
  return handleOnRender();
}
