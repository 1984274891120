import React, { ReactElement, useState } from 'react';

import {
  Button,
  ContentGroup,
  GridItem,
  Heading,
  Spinner,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { authSmsOtp } from '../../apis/authSMSOTPApi';
import { authZAPI } from '../../apis/authZApi';
import { authZTokenAPI } from '../../apis/authZTokenApi';
import { getEmailAPI } from '../../apis/getEmailApi';
import SuccessIcon from '../../assets/icons/successIcon';
import dataQaIds from '../../dataModel/dataQaIds';
import * as routes from '../../routes/manifest';
import {
  OtpAuthenticateProps,
  RegistrationContent,
} from '../../routes/registration/Registration.config';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { forgotEmailAction } from '../../store/action/login.action';
import { AuthTokenAction } from '../../store/action/registration.action';
import { challenge, verifier } from '../../utils/codeGenerator';
import { setCookie } from '../../utils/cookie';
import { passwordExpiryDateFormat } from '../../utils/dateFormat';
import { isNftInt, isPre } from '../../utils/handleEnvVariables';
import { isEmptyString } from '../../utils/isEmptyString';
import { StyledButtonsDiv } from '../appConfig/common.styled';
import DataModal from '../dataModal/dataModal';
import LinkButton from '../linkButton/LinkButton';
import OTPField from '../otpField/otpField';

export default function OTPAuthentication({
  isForgotEmail,
  sessionId,
  expiryTime,
  phoneNumber,
  mtaCustomerId,
  handleSendOtp,
  handleStepper,
  brand,
  handleSessionId,
  isLogin,
}: OtpAuthenticateProps): ReactElement {
  const [otpInputs, setOtpInputs] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const {
    invalidPasscodeErrorMessage,
    expiredPasscodeErrorMessage,
    verifyText,
    sendPasscodeText,
    passcodeFieldLabel,
    resendPasscodeButton,
    forgotEmailDataModalButtonText,
    forgotEmailDataModalDescription,
    forgotEmailDataModalHeading,
    continueLabel,
    passcodeExpiredApiErrMessage,
    authzFailureErrorMsg,
    passcodeExpiryTimeText,
    passcodeExpiryMinutes,
    otpVerifyHeading,
  } = useContent<RegistrationContent>();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();
  const navigate = useNavigate();
  const customerDetails = useSelector((state: RootState) => state.LoginReducer);
  const { customerData } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );

  const handleNavigateLogin = (): void => {
    dispatch(DataModelAction(false));
    navigate(routes.WelcomePage);
  };

  const handleErrorMessge = (
    error: [
      {
        authStatus: string;
        message: string;
      },
    ],
  ): void => {
    handleSessionId(error[0].message);
    if (
      error[0].authStatus === 'PREVIOUS_OTP' ||
      error[0].authStatus === 'OTP_INVALID' ||
      error[0].authStatus === 'AUTHENTICATION_FAILURE'
    ) {
      setErrorMsg(invalidPasscodeErrorMessage);
      setIsValidOtp(false);
    }
    if (
      error[0].authStatus === 'OTP_EXPIRED' ||
      error[0].message === passcodeExpiredApiErrMessage
    ) {
      setErrorMsg(expiredPasscodeErrorMessage);
      setIsValidOtp(false);
    }
  };

  const getEmail = (accessToken: string) => {
    setShowSpinner(true);
    getEmailAPI(
      customerData[0].isWSS ? customerData[0].wssCustomerID : mtaCustomerId,
      {
        Authorization: `Bearer ${accessToken}`,
      },
    )
      .then((res: any) => {
        setShowSpinner(false);
        setIsValidOtp(true);
        dispatch(DataModelAction(true));
        dispatch(forgotEmailAction(res.Data.emailAddress));
      })
      .catch(() => {
        setShowSpinner(false);
      });
  };
  const handleAuthResponse = (auth, res) => {
    const dateExpiry = new Date().getTime() + 30 * 60000;
    const accessToken = isPre ? auth : auth.access_token;

    setShowSpinner(false);
    dispatch(AuthTokenAction(accessToken));
    setCookie('refreshToken', auth?.refresh_token, dateExpiry);
    setCookie('authCode', res?.authorization_code, dateExpiry);
    setCookie('verifier', verifier, dateExpiry);
    getEmail(accessToken);
  };

  const handleAuthorization = (response) => {
    if (!isNftInt && isForgotEmail) {
      authZAPI(response.accessToken, challenge)
        .then((res: { authorization_code: string }) => {
          if (isPre) {
            handleAuthResponse(res, response);
            return;
          }
          authZTokenAPI(res.authorization_code, verifier)
            .then((auth: { access_token: string; refresh_token: string }) => {
              handleAuthResponse(auth, res);
            })
            .catch(() => {
              setShowSpinner(false);
              setErrorMsg(authzFailureErrorMsg);
            });
        })
        .catch(() => {
          setShowSpinner(false);
          setErrorMsg(authzFailureErrorMsg);
        });
    } else {
      getEmail('123');
    }
  };

  const handleOtpValidation = (otpValue: string): void => {
    authSmsOtp(sessionId, otpValue)
      .then((res: { accessToken: string }) => {
        if (isForgotEmail) {
          handleAuthorization(res);
        } else {
          handleStepper();
          setShowSpinner(false);
          dispatch(AuthTokenAction(res.accessToken));
        }
      })
      .catch((error) => {
        setShowSpinner(false);
        handleErrorMessge(error?.response?.data[0].errors.errors);
      });
  };
  const handleOnEmptyFields = (isFieldError: boolean): void => {
    if (isFieldError) setErrorMsg(invalidPasscodeErrorMessage);
  };
  const handleOtpValues = (value: string): void => {
    setOtpInputs(value);
    setErrorMsg('');
  };
  const handleOnClick = (): void => {
    if (
      otpInputs.length === 6 &&
      !isEmptyString(otpInputs) &&
      isEmptyString(errorMsg)
    )
      handleOtpValidation(otpInputs);
    else setErrorMsg(invalidPasscodeErrorMessage);
  };
  return (
    <>
      <GridItem>
        <Heading size="s4" marginBottom="02" aria-level={3}>
          {isLogin ? otpVerifyHeading : verifyText}
        </Heading>
        <Text size="s2" data-cs-mask>
          {sendPasscodeText}
          {phoneNumber}
        </Text>
        <br />
        <ContentGroup marginTop="02" marginBottom="none">
          <Text size="s1">
            {passcodeExpiryTimeText}
            {passwordExpiryDateFormat(expiryTime)}
            {passcodeExpiryMinutes}
          </Text>
        </ContentGroup>
        {showSpinner ? (
          <ContentGroup marginBottom="07" marginTop="05">
            <Spinner />
          </ContentGroup>
        ) : (
          <OTPField
            label={passcodeFieldLabel}
            name="otp"
            fieldMarginTop="05"
            fieldMarginBottom="07"
            handleOtpValues={handleOtpValues}
            numOfValues={6}
            otpValues={otpInputs}
            error={errorMsg}
            testId="otpField"
            handleOnEmptyFields={handleOnEmptyFields}
            dataQaId={dataQaIds.loginAndRegistration.passcodeField}
          />
        )}
        <StyledButtonsDiv>
          <LinkButton
            handleButtonClick={handleSendOtp}
            testId="resendPasscode"
            dataQaId={dataQaIds.loginAndRegistration.resendPasscodeBtn}
          >
            {resendPasscodeButton}
          </LinkButton>
          <Button
            onClick={handleOnClick}
            data-testid="continueBtn"
            data-qa-id={dataQaIds.loginAndRegistration.continueBtn}
          >
            {continueLabel}
          </Button>
        </StyledButtonsDiv>
      </GridItem>

      {isForgotEmail && isValidOtp && (
        <DataModal
          brand={brand}
          onClick={() => handleNavigateLogin()}
          closeIcon={false}
          icon={<SuccessIcon colour={theme.color_text_default_1} />}
          description={`${forgotEmailDataModalDescription} <span data-cs-mask>${customerDetails.emailAddress}<span>`}
          heading={forgotEmailDataModalHeading}
          buttonTxt={forgotEmailDataModalButtonText}
          dataQaId={{
            btn: dataQaIds.loginAndRegistration.regcompleteBtn,
          }}
          popupName="Identity verified"
        />
      )}
    </>
  );
}
