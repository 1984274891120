export interface IFormStepState {
  stepNumber: number;
  numberOfSteps: number;
  subSteps?: { description: string; isActive: boolean }[];
}

const initialState = {
  stepNumber: 1,
  numberOfSteps: 0,
  subSteps: [],
};

export const FormStepsReducer = (
  state: IFormStepState = initialState,
  action: any = {
    type: '',
    ...initialState,
  },
): IFormStepState => {
  switch (action.type) {
    case 'CHANGE_STEP':
      return {
        ...state,
        stepNumber: action.stepNumber,
      };

    case 'CHANGE_NUMBER_OF_STEPS':
      return {
        ...state,
        numberOfSteps: action.numberOfSteps,
      };
    case 'CHANGE_SUB_STEPS':
      return {
        ...state,
        subSteps: action.subSteps,
      };

    default:
      return state;
  }
};
