import {
  ContentGroup,
  Grid,
  Heading,
  Paragraph,
  Text,
} from '@constellation/core';
import styled from 'styled-components';

import { StyledLink } from '../../components/appLayout/Applayout.styled';

interface IPrivacyNotice {
  isPaddingLeft?: boolean;
  isBold?: boolean;
  isSubheading?: boolean;
}

export const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  word-wrap: break-word;
`;

export const StyledSubHeading = styled(Heading)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  font-weight: ${({ isBold }: IPrivacyNotice) =>
    isBold ? '600' : ''} !important;
  padding-top: ${({ isSubheading }: IPrivacyNotice) =>
    isSubheading ? ({ theme }) => theme.spacing_05 : 'none'} !important;
`;
export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
`;

export const StyledPrevContentGroup = styled(ContentGroup)`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.color_text_brand_1} !important;
  margin-left: ${({ theme }) => theme.spacing_02} !important;
  margin-bottom: ${({ theme }) => theme.spacing_03} !important;
`;

export const StyledHeader = styled(ContentGroup)(
  () => `
      display: flex;
      align-items:center;
      `,
);

export const StyledPreviousLink = styled(StyledLink)`
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  min-width: auto !important;
  color: ${({ theme }) => theme.color_text_brand_1} !important;
  text-decoration: underline !important;
  border-radius: 0 !important;
  &:focus:hover {
    box-shadow: none !important;
  }
`;

export const StyledGrid = styled(Grid)(
  ({ theme }) => `
  margin-bottom:  ${theme.spacing_01} !important;
  width: 100%;`,
);

export const StyledInnerDiv = styled('div')`
  > span,
  p,
  ul > li span svg,
  li > span {
    color: white !important;
  }
  padding: 1rem !important;
  padding-bottom: ${({ theme }) => theme.spacing_05}!important;
  > a {
    color: #0f7eb1 !important;
  }
  a:focus {
    background: none !important;
    box-shadow: none !important;
  }
  &.mobileFound {
    padding: 1rem !important;
  }
  &.notMobile {
    padding: 1.5rem !important;
  }
`;
export { StyledLink };
