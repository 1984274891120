import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ContentGroup,
  GridItem,
  MemorableDate,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useSelector } from 'react-redux';

import { FormSubSteps } from '../../constants/formSubSteps';
import { DATE_PATTERN } from '../../constants/REGEX';
import dataQaIds from '../../dataModel/dataQaIds';
import {
  DateObject,
  RegistrationContent,
  RegistrationDetailsProps,
} from '../../routes/registration/Registration.config';
import { RootState } from '../../store';
import { dateFormat } from '../../utils/dateFormat';
import { isEmptyString } from '../../utils/isEmptyString';
import AgreementField from '../agreementField/agreementField';
import { DateProps } from '../appConfig/AppConfig';
import PostcodeField from '../postcodeField/postcodeField';

export default function RegistrationYourDetails({
  handleOnPostCodeError,
  handleOnDateError,
  isContinueClicked,
  handleOnDate,
  handleOnPostCode,
  isDateField,
  setIsDateField,
  setIsContinueClicked,
  isMoreThanOneCustomer,
  handleAgreementNumber,
}: RegistrationDetailsProps): ReactElement {
  const { subSteps } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const {
    dateErrorMessageLabel,
    enterInfoLabel,
    whatIsDobLabel,
    whatIsPostcodeLabel,
  } = useContent<RegistrationContent>();
  const getSubStepName = useCallback((): string => {
    return (
      subSteps.length > 0 &&
      subSteps.filter((item) => item.isActive).length > 0 &&
      subSteps.filter((item) => item.isActive)[0].description
    );
  }, [subSteps]);

  useEffect(() => {
    getSubStepName();
  }, [getSubStepName, subSteps]);

  const datePattern = useMemo(() => {
    return new RegExp(DATE_PATTERN);
  }, []);

  const { dob } = useSelector((state: RootState) => state.RegistrationReducer);
  const [date, setDate] = useState<DateProps>(
    dob || {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  );
  const [dateErrorMessage, setDateErrorMessage] = useState('');
  const today = new Date();
  const getAge = (): number => {
    const birthDate = new Date(dateFormat(date, 'yyyy/mm/dd'));
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age;
  };
  const emptyDayField = useCallback(
    (): boolean => isEmptyString(date.day),
    [date],
  );
  const emptyMonthField = useCallback(
    (): boolean => isEmptyString(date.month),
    [date],
  );
  const emptyYearField = useCallback(
    (): boolean => isEmptyString(date.year),
    [date],
  );
  const handleDataFormat = useCallback(
    (): boolean => datePattern.test(dateFormat(date, 'yyyy-mm-dd')),
    [date, datePattern],
  );
  const handleOnDateValidation = (event: any): void => {
    const { name } = event.target;
    if (name.includes('year')) {
      if (
        getAge() < 18 ||
        emptyDayField() ||
        emptyMonthField() ||
        emptyYearField() ||
        parseFloat(date.year) > today.getFullYear() ||
        !handleDataFormat()
      ) {
        handleOnDateError(true);
        setDateErrorMessage(dateErrorMessageLabel);
      } else {
        handleOnDateError(false);
        setDateErrorMessage('');
      }
    }
    if (name.includes('month') && date.month === '0') {
      setDateErrorMessage(dateErrorMessageLabel);
    }
    if (name.includes('day') && date.day === '0') {
      setDateErrorMessage(dateErrorMessageLabel);
    }
  };
  const handleOnDateChange = (event: DateObject): void => {
    setDate(event);
    handleOnDate(event);
    setDateErrorMessage('');
    setIsDateField(false);
  };
  useEffect(() => {
    if (
      isDateField &&
      (emptyDayField() ||
        emptyMonthField() ||
        emptyYearField() ||
        !handleDataFormat())
    ) {
      setDateErrorMessage(dateErrorMessageLabel);
    } else {
      handleOnDateError(false);
    }
  }, [
    handleOnDateError,
    dateErrorMessageLabel,
    handleDataFormat,
    emptyDayField,
    emptyMonthField,
    emptyYearField,
    isDateField,
    handleOnDate,
    dob,
  ]);
  const renderDobPostcodeField = (): ReactElement => {
    return (
      <>
        <GridItem>
          <Text size="s2" weight="bold">
            {whatIsDobLabel}
          </Text>
        </GridItem>
        <ContentGroup marginLeft="03" marginBottom="none" marginTop="none">
          <MemorableDate
            data-testid="dateField"
            onChange={(event) => handleOnDateChange(event)}
            onBlur={handleOnDateValidation}
            marginBottom="05"
            data-qa-id={dataQaIds.loginAndRegistration.dateField}
            label=""
            day={date.day}
            month={date.month}
            year={date.year}
            name="dateField"
            error={dateErrorMessage}
            data-cs-mask
          />
        </ContentGroup>
        <GridItem>
          <ContentGroup marginBottom="none">
            <Text size="s2" weight="bold">
              {whatIsPostcodeLabel}
            </Text>
          </ContentGroup>
        </GridItem>
        <GridItem>
          <ContentGroup marginBottom="07">
            <PostcodeField
              isContinueClicked={isContinueClicked}
              handleOnPostCodeError={handleOnPostCodeError}
              handleOnPostCode={handleOnPostCode}
              setIsContinueClicked={setIsContinueClicked}
            />
          </ContentGroup>
        </GridItem>
      </>
    );
  };
  const renderAgreementField = (): ReactElement => {
    return (
      <GridItem lg={8} md={6.5} sm={12}>
        <AgreementField
          isContinueClicked={isContinueClicked}
          setIsContinueClicked={setIsContinueClicked}
          handleAgreementNumber={handleAgreementNumber}
        />
      </GridItem>
    );
  };
  return (
    <>
      <GridItem>
        <ContentGroup marginBottom="05">
          <Text size="s2" data-testid="registrationYourDetails-info">
            {enterInfoLabel}
          </Text>
        </ContentGroup>
      </GridItem>
      {getSubStepName() === FormSubSteps.MULTI_AGREEMENT ||
      isMoreThanOneCustomer
        ? renderAgreementField()
        : renderDobPostcodeField()}
    </>
  );
}
