import forgotPassword from './forgotPassword';
import registration from './registration';
import { AppContent } from '../AppContent';

const forgotEmail: AppContent['forgotEmail'] = {
  noCustomerFoundDescription:
    'This is because the incorrect details have been entered or you have not yet signed an agreement.',
  userNotRegisteredErrorMessage: 'User not registered.',
  ...registration,
  ...forgotPassword,
};

export default forgotEmail;
