import React, { ReactElement } from 'react';

import { ContentGroup, GridItem, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import {
  RegistrationContent,
  RegistrationCredentialsProps,
} from './Registration.config';

export default function RegistrationCredentials({
  email,
}: RegistrationCredentialsProps): ReactElement {
  const { createYourAccountLabel, credentialsConfirmEmailLabel } =
    useContent<RegistrationContent>();
  return (
    <>
      <GridItem>
        <ContentGroup marginBottom="02">
          <Text size="s4">{createYourAccountLabel}</Text>
        </ContentGroup>
      </GridItem>
      <GridItem>
        <ContentGroup marginBottom="05" marginTop="none">
          <Text size="s2" weight="bold">
            {credentialsConfirmEmailLabel}
          </Text>
        </ContentGroup>
      </GridItem>
      <GridItem>
        <ContentGroup marginBottom="05" marginTop="none">
          <Text size="s2" color="subdued" data-cs-mask>
            {email}
          </Text>
        </ContentGroup>
      </GridItem>
    </>
  );
}
