import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ForgotEmailMTA from './forgotEmailMTA';
import { RootState } from '../../store';
import { changeStepAction } from '../../store/action/formStep.action';
import { ContactNumberFoundAction } from '../../store/action/registration.action';
import { isEmptyString } from '../../utils/isEmptyString';
import { ForgotEmailProps } from '../forgotPassword/forgotPassword.config';
import { ApiAuthResponse } from '../registration/Registration.config';

export default function ForgotEmail({ brand }: ForgotEmailProps) {
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const { customerData, isContactNumberFound } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const dispatch = useDispatch();

  const [mtaCustomerId, setMtaCustomerId] = useState('');
  const [wssCustomerID, setWssCustomerID] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isContactFound, setIsContactFound] = useState(isContactNumberFound);
  const [isCustomerIdentificationError, setIsCustomerIdentificationError] =
    useState(false);
  const numberOfSteps = 2;
  const isNoContactFound = (number: string): boolean => {
    if (isEmptyString(number)) {
      setIsContactFound(false);
      return true;
    }
    setIsContactFound(true);
    return false;
  };

  const handleOnStep = () => {
    dispatch(
      changeStepAction(
        stepNumber < numberOfSteps ? stepNumber + 1 : numberOfSteps,
      ),
    );
  };
  const handleIsContactFound = (value: boolean) => setIsContactFound(value);
  const handleOnNoContactCondition = (number: string): void => {
    if (isNoContactFound(number)) {
      setIsCustomerIdentificationError(true);
      setIsContactFound(false);
      dispatch(ContactNumberFoundAction(false));
    } else {
      setPhoneNumber(number);
      setIsContactFound(true);
      dispatch(ContactNumberFoundAction(true));
      handleOnStep();
    }
  };
  const handleCustomerIdentityError = (bool): void => {
    setIsCustomerIdentificationError(bool);
  };

  const handleOnModalConditions = (res: ApiAuthResponse): void => {
    handleOnNoContactCondition(res.obscuredPhoneNumber);
    setMtaCustomerId(res.mtaCustomerId ? res.mtaCustomerId : '');
    setWssCustomerID(res.wssCustomerID ? res.wssCustomerID : '');
  };
  return (
    <ForgotEmailMTA
      brand={brand}
      phoneNumber={phoneNumber || customerData[0].obscuredPhoneNumber}
      mtaCustomerId={mtaCustomerId || customerData[0].mtaCustomerId}
      handlePassDetails={handleOnModalConditions}
      isContactFound={isContactFound}
      isCustomerIdentificationError={isCustomerIdentificationError}
      numberOfSteps={numberOfSteps}
      handleCustomerIdentityError={handleCustomerIdentityError}
      handleIsContactFound={handleIsContactFound}
      wssCustomerID={wssCustomerID || customerData[0].wssCustomerID}
    />
  );
}
