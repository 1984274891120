import { combineReducers, legacy_createStore as createStore } from 'redux';

import { DataModalReducer } from './reducer/dataModalReducer';
import { FormStepsReducer } from './reducer/formStepsReducer';
import { LoginReducer } from './reducer/loginReducer';
import { MultiAgreementReducer } from './reducer/multiAgreementReducer';
import { ProfileReducer } from './reducer/profileReducer';
import { RegistrationReducer } from './reducer/registrationReducer';
import { UtilsReducer } from './reducer/utilsReducer';

const RootReducers = combineReducers({
  LoginReducer,
  UtilsReducer,
  DataModalReducer,
  FormStepsReducer,
  RegistrationReducer,
  ProfileReducer,
  MultiAgreementReducer,
});

export type RootState = ReturnType<typeof RootReducers>;
const store = createStore(RootReducers);
export default store;
