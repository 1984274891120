export enum REGEX {
  EMAIL_PATTERN = '^[a-zA-Z0-9](?:[A-Za-z0-9\\._-])+@([A-Za-z0-9.-]+)\\.[a-zA-Z]{2,}$',
  SPECIAL_CHARACTERS_EMAIL = '!#$^&*()?<>,:;""{}[]+%=/|\\',
  POSTCODE_MATCH = '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Z0-9]? ?[0-9][A-Za-z]{2}|GIR ?0A{2})$',
  ONLY_NUMBERS = '^[0-9]*$',
}
export const numberPattern = /\D/g;
export const passwordPattern = {
  containMinCharaters: '^.{8,32}$',
  containNumber: '\\d',
  containUpperCase: '[A-Z]',
  containLowerCase: '[a-z]',
  containSpecialCharacter: '[!@#$%=^&"~£€¬*(),.?_+":;{}|<>`\\[\\]/\'\\\\-]',
};
export const passwordCharacters =
  '^[a-zA-Z0-9!@#$%=^&"~£€¬*(),.?_+":;{}|<>`\\[\\]/\'\\\\-]+$';

export const EMAIL_PATTERN =
  '^[a-zA-Z0-9](?:[A-Za-z0-9\\._-])+@[A-Za-z0-9]+\\.[a-zA-Z]{2,3}$';
export const SPECIAL_CHARACTERS_EMAIL = '!#$^&*()?<>,:;""{}[]+%=/|\\';

export const DATE_PATTERN =
  '^(19|20)\\d\\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$';

export const PASSWORD_ADD_PATTERN =
  '[s]{0,140}?(?:(?:delete)|(?:waitfor)|(?:delay)|(?:echo)|(?:openrowset)|(?:utl_http)|(?:utl_inaddr)|(?:$ifs)|(?:echo)|(?:vbs)|(?:string.fromcharcode)|(?:document.cookie)|(?:config)|(?:printenv)|(?:benchmark)|(?:ascii)|(?:substring)|(?:concat)|(?:exec)|(?:load_file)|(?:/etc/passwd)|(?:\\etc\\passwd)|(?:\\.\\.\\\\){1,10}?|(?:\\.\\./){1,10}?|(?:win.ini)|(?:exec)|(?:drop\\s*table)|(?:insert)|(?:shutdown)|(?:update)|(?:select(?! credit| cash))|(?:javascript)|(?:script)|(?:or))|(?:(?:seekSegmentTime|FSCommand))|(?:on(?:(?:Abort|Activate|After|Before|Begin|Blur|Bounce|CellChange|change|click|contextMenu|controlSelect|Copy|Cut|DataAvailable|DataSet|DblClick|Deactivate|Drag|Drop|End|Error|FilterChange|Finish|Focus|HashChange|Help|Input|Key|LayoutComplete|Load|LoseCapture|MediaComplete|MediaError|Message|Mouse|Move|Offline|Online|OutOfSync|Paste|Pause|PopState|Progress|PropertyChange|ReadyStateChange|Redo|Repeat|Reset|Resize|Resume|Reverse|Row|Scroll|Seek|Select|Start|Stop|Storage|SyncRestored|Submit|TimeError|TrackChange|Undo|Unload|URLFlip)))';
