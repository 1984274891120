import React, { ReactElement, useEffect } from 'react';

import { Grid, Heading, GridItem, Button, Text } from '@constellation/core';
import { useSelector } from 'react-redux';

import { DataModalProps } from './DataModel.config';
import {
  StyledDataModalLink,
  StyledDialog,
  StyledDialogButtonGrid,
  StyledPhoneLinkContainer,
} from './styledDataModal';
import { RootState } from '../../store';
import { isEmptyString } from '../../utils/isEmptyString';
import { createPopupDataLayer } from '../../utils/popupDataLayer';
import LinkButton from '../linkButton/LinkButton';
import PhoneLink from '../phoneLink/PhoneLink';

export default function DataModal({
  icon,
  heading,
  description,
  buttonTxt,
  onClick,
  closeIcon,
  onClose,
  link,
  onClickLink,
  dataQaId,
  isPhoneLink,
  brand,
  popupName,
}: DataModalProps): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataModelDetails = useSelector(
    (state: RootState) => state.DataModalReducer,
  );
  const renderPhoneLink = (): ReactElement => {
    return <PhoneLink dataQaId={dataQaId.link} brand={brand} />;
  };
  useEffect(() => {
    const dataLayerObject = createPopupDataLayer(popupName, 'opened');
    window.utag?.view(dataLayerObject);
    return () => {
      const closeDataLayerObject = createPopupDataLayer(popupName, 'closed');
      window.utag?.view(closeDataLayerObject);
    };
  }, [popupName]);
  return (
    <StyledDialog
      autoFocus={false}
      open={dataModelDetails.openDialog}
      closeButton={closeIcon}
      onClose={onClose}
      data-testid="dataModalPopup"
    >
      <Grid alignX="center" alignY="center">
        {icon}
      </Grid>
      <Heading
        as="h4"
        size="s4"
        align="center"
        marginTop="05"
        marginBottom="05"
        data-testid="dataModalHeading"
      >
        {heading}
      </Heading>
      <GridItem>
        <Grid alignX="center" alignY="center">
          <Text
            size="s2"
            data-testid="dataModalDescription"
            align="center"
            marginBottom={isPhoneLink ? '05' : 'none'}
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Text>

          {isPhoneLink && (
            <Grid alignX="center" alignY="center">
              {!isEmptyString(buttonTxt) ? (
                renderPhoneLink()
              ) : (
                <StyledPhoneLinkContainer>
                  {renderPhoneLink()}
                </StyledPhoneLinkContainer>
              )}
            </Grid>
          )}
        </Grid>
      </GridItem>
      {!isEmptyString(buttonTxt) && (
        <GridItem>
          <StyledDialogButtonGrid
            alignX="center"
            alignY="center"
            isLink={isEmptyString(link)}
          >
            <Button
              data-testid="dataModalBtn"
              onClick={onClick}
              data-qa-id={dataQaId.btn}
            >
              <GridItem>{buttonTxt}</GridItem>
            </Button>
          </StyledDialogButtonGrid>
        </GridItem>
      )}
      {link && (
        <StyledDataModalLink alignX="center" alignY="center">
          <LinkButton
            testId="dataModalLink"
            handleButtonClick={onClickLink}
            dataQaId={dataQaId.link}
          >
            {link}
          </LinkButton>
        </StyledDataModalLink>
      )}
    </StyledDialog>
  );
}
DataModal.defaultProps = { link: '', closeIcon: false };
