import React, { ReactElement, useRef } from 'react';

import {
  GridItem,
  ContentGroup,
  Text,
  TextField,
  Grid,
  Icon,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';

import LoginModal from './loginModal';
import { ILoginCredentialsProps, WelcomeContent } from './WelcomePage.config';
import {
  StyledInlineBox,
  StyledTextBox,
} from '../../components/appConfig/common.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import PasswordField from '../../components/passwordField/passwordField';
import { REGEX } from '../../constants/REGEX';
import dataQaIds from '../../dataModel/dataQaIds';
import { isEmptyString } from '../../utils/isEmptyString';
import * as routes from '../manifest';

export default function LoginCredentials({
  handleOnLoginCredentialError,
  handlePwd,
  handleUserName,
  isLoginCredentialError,
  emailFieldErrorMsg,
  handleOnEmailSetErrMsg,
  handleOnPasswordSetErrMsg,
  passwordFieldErrorMsg,
  emailId,
  handleWssDown,
  isWssDown,
}: ILoginCredentialsProps): ReactElement {
  const emailValue = useRef({} as HTMLInputElement);
  const emailPattern = new RegExp(REGEX.EMAIL_PATTERN);
  const navigate = useNavigate();
  const {
    emailErrorMessage,
    logIntoAccountLabel,
    detailsDontLookRightError,
    emailAddressLabel,
    forgottenEmailLink,
    passwordLabel,
    forgottenPasswordLink,
    emptyFieldErrorMessage,
    wssErrorMessage,
  } = useContent<WelcomeContent>();
  const handleEmailKeyDown = (event: any): boolean => {
    if (REGEX.SPECIAL_CHARACTERS_EMAIL.indexOf(event.key) !== -1) {
      event.preventDefault();
      return false;
    }
    handleOnEmailSetErrMsg('');
    handleOnLoginCredentialError(false);
    handleWssDown(false);
    return true;
  };

  const handleOnEmailBlurEvt = (): void => {
    if (isEmptyString(emailValue.current.value.trim())) {
      handleOnEmailSetErrMsg(emptyFieldErrorMessage);
    } else if (
      !isEmptyString(emailValue.current.value) &&
      !emailPattern.test(emailValue.current.value)
    ) {
      handleOnEmailSetErrMsg(emailErrorMessage);
    }
    handleUserName(emailValue.current.value);
  };
  const handlePwdBlurEvt = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ): void => {
    const { value } = event.target;
    if (isEmptyString(value)) {
      handleOnPasswordSetErrMsg(emptyFieldErrorMessage);
    } else {
      handleOnPasswordSetErrMsg('');
      handlePwd(value);
    }
  };
  const renderForgotEmailLink = (): void => {
    navigate(routes.ForgotEmail);
  };
  const handleOnForgotPassword = (): void => {
    navigate(routes.ForgotPassword);
  };

  const renderErrorMessage = (errMessage: string): ReactElement => (
    <GridItem>
      <StyledTextBox marginTop="none" marginBottom="07" bgColor="warning">
        <GridItem>
          <Grid>
            <GridItem md={12} sm={12} lg={12}>
              <Icon name="warning" color="warning" iconSize="lg" />
            </GridItem>
            <GridItem md={12} sm={12} lg={12}>
              <ContentGroup
                marginLeft="none"
                marginRight="none"
                marginBottom="none"
                marginTop="04"
              >
                <Text size="s2" align="left">
                  {errMessage}
                </Text>
              </ContentGroup>
            </GridItem>
          </Grid>
        </GridItem>
      </StyledTextBox>
    </GridItem>
  );

  return (
    <>
      <Grid alignX="center">
        <ContentGroup marginBottom="05">
          <Text size="s5">{logIntoAccountLabel}</Text>
        </ContentGroup>
      </Grid>
      {isLoginCredentialError && renderErrorMessage(detailsDontLookRightError)}
      {isWssDown && renderErrorMessage(wssErrorMessage)}
      <GridItem>
        <ContentGroup marginBottom="none">
          <TextField
            name="email"
            label={emailAddressLabel}
            inputRef={emailValue}
            onKeyDown={handleEmailKeyDown}
            onBlur={handleOnEmailBlurEvt}
            onChange={(e: React.FocusEvent<HTMLInputElement, Element>) =>
              handleUserName(e.target.value)
            }
            marginBottom="04"
            data-testid="emailId"
            defaultValue={emailId}
            error={emailFieldErrorMsg}
            inputWidth="fluid"
            data-qa-id={dataQaIds.loginAndRegistration.loginCredentialsEmail}
            autoComplete="off"
            data-cs-mask
          />
        </ContentGroup>
        <StyledInlineBox>
          <LinkButton
            handleButtonClick={renderForgotEmailLink}
            testId="forgot-email-link"
            dataQaId={
              dataQaIds.loginAndRegistration.loginCredentialsForgotEmailLink
            }
          >
            {forgottenEmailLink}
          </LinkButton>
        </StyledInlineBox>
      </GridItem>
      <GridItem>
        <ContentGroup marginTop="05" marginBottom="none">
          <PasswordField
            label={passwordLabel}
            name="password"
            testId="password"
            onBlur={handlePwdBlurEvt}
            onChange={(e: React.FocusEvent<HTMLInputElement, Element>) => {
              handleOnLoginCredentialError(false);
              handleOnPasswordSetErrMsg('');
              handlePwd(e.target.value);
            }}
            inputRef={undefined}
            error={passwordFieldErrorMsg}
            inputWidth="fluid"
            dataQaId={dataQaIds.loginAndRegistration.loginCredentialsPassword}
            fieldMarginBottom="04"
            data-cs-mask
          />
        </ContentGroup>
        <ContentGroup marginBottom="07" marginTop="none">
          <StyledInlineBox>
            <LinkButton
              handleButtonClick={handleOnForgotPassword}
              testId="forgot-password-link"
              dataQaId={
                dataQaIds.loginAndRegistration
                  .loginCredentialsForgotPasswordLink
              }
            >
              {forgottenPasswordLink}
            </LinkButton>
          </StyledInlineBox>
        </ContentGroup>
      </GridItem>
      <LoginModal />
    </>
  );
}
