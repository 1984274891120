import { Box, Container, GridItem } from '@constellation/core';
import styled from 'styled-components';

import WelcomePageDesktop from '../../assets/common/welcome-page-desktop.png';
import WelcomePageMobile from '../../assets/common/welcome-page-mobile.png';
import WelcomePageTablet from '../../assets/common/welcome-page-tablet.png';
import {
  StyledBox,
  StyledBoxProps,
} from '../../components/appConfig/common.styled';
import { device } from '../../utils/breakpoints';

export const StyledBackgroundImage = styled('div')`
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background: url(${WelcomePageMobile});
  @media ${device.md} {
    background: url(${WelcomePageTablet});
  }
  @media ${device.lg} {
    background: url(${WelcomePageDesktop});
  }
`;
export const StyledBoxContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100wh;
  @media ${device.md} {
    align-items: center;
  }
  @media ${device.lg} {
    justify-content: center;
    align-items: flex-end;
  }
`;
export const StyledContentBox = styled(Box)`
  border-radius: ${({ theme }) => theme.spacing_02};
  background: ${({ theme }) =>
    theme.color_background_panel_default_2} !important;
  padding-top: ${({ theme }) => theme.spacing_05} !important;
  padding-bottom: ${({ theme }) => theme.spacing_05} !important;
  margin-top: auto !important;
  margin-bottom: ${({ theme }) => theme.spacing_04} !important;
  @media ${device.md} {
    width: 500px;
    margin-bottom: ${({ theme }) => theme.spacing_07} !important;
  }
  @media ${device.lg} {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
`;

export const StyledLoginBtnGridItem = styled(GridItem)`
  margin-bottom: ${({ theme }) => theme.spacing_04};
  @media ${device.md} {
    margin-bottom: 0;
  }
`;

export const StyledWelcomeHeader = styled('header')`
  padding: ${({ theme }) => `${theme.spacing_04} 0`};
  @media ${device.lg} {
    padding-top: ${({ theme }) =>
      `calc(${theme.spacing_07} + ${theme.spacing_04})`};
    padding-bottom: 0;
  }
  position: fixed;
  top: 0;
  z-index: 2;
  height: ${({ theme }) => theme.spacing_07};
  width: 100%;
`;

export const StyledPasswordContainer = styled(StyledBox)<StyledBoxProps>`
  margin-bottom: 0 !important;
  ${({ padding, theme }) => {
    if (padding) {
      return `padding-bottom: ${theme.spacing_05} !important`;
    }
    return '';
  }}
`;

export const StyledPrevLink = styled('div')`
  & > span {
    margin-left: 0 !important;
    padding-left: 0 !important;
    border: none;
    margin-bottom: ${({ theme }) => theme.spacing_03} !important;
  }
`;
