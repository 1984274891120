import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useDispatch, useSelector } from 'react-redux';

import { authSMSSender } from '../../apis/authSMSSenderApi';
import { forgotPasswordApi } from '../../apis/forgotPasswordApi';
import { StyledBox } from '../../components/appConfig/common.styled';
import FormStepper from '../../components/formStepper/FormStepper';
import NavigatePreviousLink from '../../components/navigatePreviousLink';
import NeedHelp from '../../components/needHelp/NeedHelp';
import OTPAuthentication from '../../components/otpAuthentication/otpAuthentication';
import PreviousLinkStepper from '../../components/previousLinkStepper/previousLinkStepper';
import { SetupPasswordContent } from '../../components/setPassword/setPassword.config';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  changeNumberOfStepsAction,
  changeStepAction,
} from '../../store/action/formStep.action';
import {
  ApiCalledAction,
  ContactNumberFoundAction,
  DOBPostcodeContractsDataAction,
  PasswordFailureAction,
} from '../../store/action/registration.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dataLayer } from '../../utils/dataLayer';
import { decodeParams } from '../../utils/decodeParams';
import { isEmptyString } from '../../utils/isEmptyString';
import { ForgotPasswordProps } from '../forgotPassword/forgotPassword.config';
import LoginAuthenticate from '../forgotPassword/loginAuthenticate';
import SetupPassword from '../forgotPassword/setupPassword';
import { ApiAuthResponse } from '../registration/Registration.config';
import RegistrationModal from '../registration/registrationModal';
import { StyledPrevLink } from '../welcomePage/Welcome.styled';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function UpdatePasswordWss({
  authToken,
  setAuthToken,
}: ForgotPasswordProps): ReactElement {
  const {
    createNewPasswordMessage,
    sendPasscodeLabel,
    needHelpLoggingIn,
    newPasswordText,
    newPasswordSubText,
    enterPasscodeHeading,
  } = useContent<SetupPasswordContent>();
  const dispatch = useDispatch();
  const { showSpinner } = useSelector((state: RootState) => state.UtilsReducer);
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const { isContactNumberFound, customerData, isApiCalled } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const { oldPassword } = useSelector((state: RootState) => state.LoginReducer);
  const isDeviceFound = useDeviceResizing();
  const [journeySessionId, setJourneySessionId] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isOTP, setIsOTP] = useState(false);
  const isApiFailed = useRef(false);

  const numberOfSteps = 2;

  const handleBackToResendPasscode = (): void => {
    setIsOTP(false);
    dispatch(changeStepAction(1));
    dispatch(PasswordFailureAction(''));
    dispatch(DataModelAction(false));
  };
  useEffect(() => {
    setEmailAddress(
      decodeParams(window.location.search.split('?')[1], 'email', 0),
    );
    window.appConfig.JOURNEY_NAME = 'Setup Password';
    window.appConfig.JOURNEY_STEP_NAME = 'Setup Password';
    window.appConfig.PAGE_ROLE = 'Servicing';
    dataLayer();
  }, []);
  const handlePreviousNavigation = () => {
    if (stepNumber === 2) {
      dispatch(changeStepAction(1));
      setIsOTP(true);
    } else {
      setIsOTP(false);
    }
  };
  const isNoContactFound = useCallback(
    (number: string): boolean => {
      if (isEmptyString(number)) {
        dispatch(ContactNumberFoundAction(false));
        return true;
      }
      dispatch(ContactNumberFoundAction(true));
      return false;
    },
    [dispatch],
  );
  const handleApiResponse = useCallback(
    (responseData: ApiAuthResponse): void => {
      if (
        !isNoContactFound(
          responseData.obscuredPhoneNumber ||
            customerData[0].obscuredPhoneNumber,
        )
      )
        dispatch(
          DOBPostcodeContractsDataAction([responseData], {
            isDetailsFound: true,
            isApiFailed: false,
            isMoreThanOneUser: false,
          }),
        );
    },
    [customerData, dispatch, isNoContactFound],
  );
  const data = useCallback((): void => {
    dispatch(ShowHideSpinnerAction(true));
    dispatch(changeNumberOfStepsAction(2));

    dispatch(changeStepAction(1));
    forgotPasswordApi(
      decodeParams(window.location.search.split('?')[1], 'email', 0),
    )
      .then((res: { data: { Data: ApiAuthResponse } }) => {
        handleApiResponse(res.data.Data);
        isApiFailed.current = false;
        dispatch(ShowHideSpinnerAction(false));
        dispatch(ApiCalledAction(true));
      })
      .catch(() => {
        dispatch(ShowHideSpinnerAction(false));
        isApiFailed.current = true;
        dispatch(DataModelAction(true));
      });
  }, [dispatch, handleApiResponse]);
  useEffect(() => {
    if (!isApiCalled) {
      data();
    }
  }, [data, isApiCalled]);

  const handleOnSessionId = (value: string): void => {
    setJourneySessionId(value);
  };
  const renderOTPComponent = (): void => setIsOTP(true);
  const handleOnClickContinue = (): void => {
    dispatch(ShowHideSpinnerAction(true));
    authSMSSender('', customerData[0].wssCustomerId)
      .then((res: any) => {
        dispatch(ShowHideSpinnerAction(false));
        setJourneySessionId(res.authSessionId);
        setExpiryTime(res.otpExpiresAt);
        renderOTPComponent();
      })
      .catch(() => {
        dispatch(ShowHideSpinnerAction(false));
      });
  };
  const handleOnStep = (): void => {
    dispatch(
      changeStepAction(
        stepNumber < numberOfSteps ? stepNumber + 1 : numberOfSteps,
      ),
    );
  };
  const renderSpinnerContainer = (): ReactElement => {
    return <SpinnerContainer brand={undefined} />;
  };

  const renderStepContent = (): ReactElement => {
    switch (true) {
      case stepNumber === 2:
        return (
          <SetupPassword
            isForgotPassword
            handleStepper={handleOnStep}
            mtaCustomerId=""
            wssCustomerId={customerData[0].wssCustomerId}
            brand={undefined}
            authToken={authToken}
            emailAddress={emailAddress}
            oldPassword={oldPassword}
            handleBackToResendPasscode={handleBackToResendPasscode}
          />
        );
      case stepNumber === 1 && isOTP:
        return (
          <OTPAuthentication
            sessionId={journeySessionId}
            expiryTime={expiryTime}
            phoneNumber={customerData[0].obscuredPhoneNumber}
            handleSendOtp={handleOnClickContinue}
            mtaCustomerId=""
            isForgetPassword
            handleSessionId={handleOnSessionId}
            brand={undefined}
            handleAuthToken={setAuthToken}
            isMobileFound={isDeviceFound.mobile}
            handleStepper={handleOnStep}
            isLogin
            isForgotEmail={false}
          />
        );
      default:
        return (
          <LoginAuthenticate
            isCustomerIdentificationError={false}
            phoneNumber={customerData[0].obscuredPhoneNumber}
            isNumberFound={isContactNumberFound}
            isLogin
          />
        );
    }
  };
  const handleOnButtonRenderCondition = (): boolean => {
    return stepNumber === 1 && !isOTP && isContactNumberFound;
  };
  const renderButton = (): ReactElement =>
    handleOnButtonRenderCondition() && (
      <Grid alignX="right">
        <Button onClick={handleOnClickContinue}>{sendPasscodeLabel}</Button>
      </Grid>
    );
  const handleStepCondition = (): boolean => stepNumber === 1 && !isOTP;
  const renderStepTitle = (): string => {
    switch (true) {
      case stepNumber === 2:
        return createNewPasswordMessage;
      case stepNumber === 1 && isOTP:
        return enterPasscodeHeading;
      default:
        return newPasswordText;
    }
  };
  const renderSubText = (): string => {
    if (handleStepCondition()) return newPasswordSubText;
    return ``;
  };
  const handleStepPreviousLink = (): ReactElement => {
    if (handleStepCondition())
      return (
        <StyledPrevLink>
          <PreviousLinkStepper />
        </StyledPrevLink>
      );
    return (
      <NavigatePreviousLink
        headingText=""
        navigateRoute={handlePreviousNavigation}
      />
    );
  };
  return showSpinner ? (
    renderSpinnerContainer()
  ) : (
    <Grid alignX="center">
      <GridItem lg={8}>
        {handleStepPreviousLink()}
        <Heading
          size="s6"
          marginBottom={handleStepCondition() ? '05' : '07'}
          marginTop="none"
          aria-level={3}
        >
          {renderStepTitle()}
        </Heading>
        <ContentGroup
          marginBottom={handleStepCondition() ? '07' : 'none'}
          marginTop="none"
        >
          <Text size="s2">{renderSubText()}</Text>
        </ContentGroup>
        <FormStepper activeStep={stepNumber} numberOfSteps={2} />
        <StyledBox padding>
          <Grid>{renderStepContent()}</Grid>
          <GridItem>{renderButton()}</GridItem>
        </StyledBox>
        <NeedHelp
          hasHeading
          customSubtitle={needHelpLoggingIn}
          dataQaId={dataQaIds.needhelp.regContainer}
        />
      </GridItem>
      <RegistrationModal
        brand={undefined}
        isAgreementFound
        isContactFound
        isCustomerRecordFound
        handleCloseWarningDialog={undefined}
        isRegistered={false}
        isApiFailed={isApiFailed.current}
        handleBackToResendPasscode={handleBackToResendPasscode}
        isLogin
        handleOnNavigateHomepage={data}
      />
    </Grid>
  );
}
